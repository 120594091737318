import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormLayout from '../FormLayout';

const SNAP_ADDRESS_REGEX =
  /^(00[.:]?1[cC][.:]?2[cC][.:]?[0-9a-fA-F]{2}[.:]?[0-9a-fA-F]{2}[.:]?)?([0-9a-fA-F][.:]?){5}[0-9a-fA-F]$/;

export default function SnapAddressModal({
  snapaddr,
  onClose,
  onSave,
}: {
  onClose: () => void;
  onSave: (snapaddr?: string | null) => void;
  snapaddr?: string | null;
}) {
  return (
    <Formik
      initialValues={{ snapaddr: snapaddr || '' }}
      validationSchema={Yup.object({
        snapaddr: Yup.string().test('Valid Snap Address', value => {
          if (!value) return true;

          return SNAP_ADDRESS_REGEX.test(value || '');
        }),
      })}
      onSubmit={values => {
        onSave(values.snapaddr);
      }}
    >
      {formik => (
        <Dialog open={true} onClose={onClose}>
          <Form>
            <DialogTitle>Specify SNAP Address</DialogTitle>
            <DialogContent style={{ width: '280px' }}>
              <FormLayout>
                <TextField
                  autoFocus
                  margin="dense"
                  id="snapaddr"
                  label="SNAP Address"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formik.values.snapaddr}
                  onChange={formik.handleChange}
                  error={formik.touched.snapaddr && Boolean(formik.errors.snapaddr)}
                  helperText={formik.touched.snapaddr && formik.errors.snapaddr}
                />
              </FormLayout>
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit" disabled={formik.isSubmitting}>
                Save SNAP Address
              </Button>
              <Button onClick={onClose} color="warning">
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
