import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../libs/axios';
import { PUT_SITE_URL, SITES_INDEX_URL } from '../const';
import FormLayout from '../FormLayout';
import { Site } from '../Models';

export default function AddSiteModal(props: { onClose: () => void }) {
  const { axios } = useAxios();
  const [isLoadingSites, setIsLoadingSites] = useState(false);
  const [sites, setSites] = useState<Site[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(SITES_INDEX_URL).then(response => {
      setSites(response.data);
      setIsLoadingSites(false);
    });
  }, [axios]);

  if (isLoadingSites) {
    return null;
  }

  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={Yup.object({
        name: Yup.string()
          .min(1)
          .required('Required')
          .test('unique', 'Name already exists', value => {
            const name = value?.trim()?.toLocaleLowerCase();

            return !sites.some(site => site.name.trim().toLocaleLowerCase() === name);
          }),
      })}
      onSubmit={async values => {
        const site: Site = {
          id: uuidv4(),
          name: values.name,
          personnel: [],
          projectIds: [],
          escalationPaths: [],
        };

        await axios.put(PUT_SITE_URL, site).then(response => {
          navigate(`/site/${response.data.id}`);
        });
      }}
    >
      {formik => (
        <Dialog open={true} onClose={props.onClose}>
          <Form>
            <DialogTitle>Add a Site</DialogTitle>
            <DialogContent>
              <FormLayout>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Site Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </FormLayout>
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit" disabled={formik.isSubmitting}>
                Save Site
              </Button>
              <Button onClick={props.onClose} color="warning">
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
