import { Auth0Provider } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppShell from './AppShell';
import './index.css';
import { AxiosProvider } from './libs/axios/';
import LoginPage from './LoginPage';
import NewProjectComponent from './projects/NewProjectComponent';
import ProjectPage from './projects/ProjectPage';
import ProjectsPage from './projects/ProjectsPage';
import { ProtectedRoute } from './ProtectedRoute';
import reportWebVitals from './reportWebVitals';
import SitePage from './sites/SitePage';
import SitesPage from './sites/SitesPage';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00aff0',
    },
    secondary: {
      main: '#f79012',
    },
    warning: {
      main: '#f04b23',
    },
    error: {
      main: '#f04b23',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="login.synapsewireless.com"
      clientId="CuGH4uMgu0pmeCOSYxteFfuqsN66L8dX"
      redirectUri={window.location.origin}
    >
      <AxiosProvider config={{ baseURL: 'https://api.simplysnapsurvey.com/' }}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AppShell>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/projects" element={<ProtectedRoute component={ProjectsPage} />} />
                <Route
                  path="/projects/new"
                  element={<ProtectedRoute component={NewProjectComponent} />}
                />
                <Route
                  path="/project/:projectId"
                  element={<ProtectedRoute component={ProjectPage} />}
                />
                <Route path="/sites" element={<ProtectedRoute component={SitesPage} />} />
                <Route path="/site/:siteId" element={<ProtectedRoute component={SitePage} />} />
              </Routes>
            </AppShell>
          </BrowserRouter>
        </ThemeProvider>
      </AxiosProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
