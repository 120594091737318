import React from "react-dom";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingIndicator() {
  return (
    <div
      style={{ display: "flex", margin: "128px 0", justifyContent: "center" }}
    >
      <CircularProgress />
    </div>
  );
}
