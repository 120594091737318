import { Add, Download } from '@mui/icons-material';
import { Fab, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import AssetComponent from '../AssetComponent';
import { GET_PROJECT_URL, GET_SITE_URL, GET_WORKBOOK_URL, PUT_PROJECT_URL } from '../const';
import { useAxios } from '../libs/axios';
import LoadingIndicator from '../LoadingIndicator';
import {
  Asset,
  EndPoint,
  EndPointSpec,
  FlattenedAsset,
  FlattenedEndPoint,
  JSONObject,
  Project,
  ProjectMode,
  Site,
} from '../Models';
import NewAssetComponent from '../NewAssetComponent';
import Page from '../Page';
import { questionsForType } from '../Questions';
import { transformProjectToAssetNameSet } from '../transformers';
import FlatAssetCard from './FlatAssetCard';
import FlatEndpointCard from './FlatEndpointCard';

export default function ProjectPage() {
  const [previousProject, setPreviousProject] = useState<Project | null>(null);
  const [project, setProject] = useState<Project | null>(null);
  const [site, setSite] = useState<Site | null>(null);
  const [shouldShowNewAssetForm, setShouldShowNewAssetForm] = useState(false);
  const { axios } = useAxios();
  const params = useParams();
  const [mode, setMode] = useState(ProjectMode.SURVEY);

  const flattenedAssets = useMemo(() => {
    if (!project) return [] as FlattenedAsset[];

    const flattenedAssets: FlattenedAsset[] = [];

    function addAsset(asset: Asset, chainOfCustody: string[], previousNames: string[]) {
      const flattenedAsset: FlattenedAsset = {
        ...asset,
        chainOfCustody: [...chainOfCustody, asset.id],
        custodyName: previousNames.join(' > '),
      };

      if (!flattenedAsset.surveyed && questionsForType(flattenedAsset.type).length > 0) {
        flattenedAssets.push(flattenedAsset);
      }

      asset.assets.forEach(childAsset =>
        addAsset(childAsset, [...chainOfCustody, asset.id], [...previousNames, asset.name])
      );
    }

    project.assets.forEach(asset => addAsset(asset, [], []));

    return flattenedAssets;
  }, [project]);

  const flattenedEndPoints = useMemo(() => {
    if (!project) return [] as FlattenedEndPoint[];

    const flattenedEndPoints: FlattenedEndPoint[] = [];

    function addFlattenedEndPoints(
      asset: Asset,
      chainOfCustody: string[],
      previousNames: string[]
    ) {
      for (let endpoint of asset.monitoring) {
        const flattenedEndPoint: FlattenedEndPoint = {
          ...endpoint,
          chainOfCustody: chainOfCustody,
          custodyName: previousNames.join(' > '),
          parentAsset: asset,
        };

        flattenedEndPoints.push(flattenedEndPoint);
      }

      asset.assets.forEach(asset =>
        addFlattenedEndPoints(asset, [...chainOfCustody, asset.id], [...previousNames, asset.name])
      );
    }

    project.assets.forEach(asset => addFlattenedEndPoints(asset, [asset.id], [asset.name]));

    return flattenedEndPoints;
  }, [project]);

  const flattenedEndpointsThatNeedToBeSpecd = useMemo(() => {
    return flattenedEndPoints.filter(endpoint => {
      return !endpoint.surveyed && questionsForType(endpoint.type).length > 0;
    });
  }, [flattenedEndPoints]);

  const traverseToAsset = (
    assets: Asset[],
    chainOfCustody: string[],
    callback: (asset: Asset) => void
  ) => {
    assets.forEach(asset => {
      if (asset.id === chainOfCustody[0]) {
        if (chainOfCustody.length === 1) {
          callback(asset);
        } else {
          return traverseToAsset(asset.assets, chainOfCustody.slice(1), callback);
        }
      }
    });
  };

  function addAsset(newAsset: Asset, chainOfCustody: string[]) {
    setPreviousProject(project);
    const updatedProject = JSON.parse(JSON.stringify(project));
    if (chainOfCustody.length === 0) {
      updatedProject.assets.push(newAsset);
    } else {
      traverseToAsset(updatedProject.assets, chainOfCustody, asset => {
        asset.assets.push(newAsset);
      });
    }
    setProject(updatedProject);
  }

  function editAsset(asset: Asset, chainOfCustody: string[]) {
    setPreviousProject(project);
    const updatedProject = JSON.parse(JSON.stringify(project)) as Project;
    const assetIdToEdit = asset.id;

    chainOfCustody = chainOfCustody.slice(0, -1);

    if (chainOfCustody.length === 0) {
      updatedProject.assets = updatedProject.assets.map(a => {
        if (a.id === assetIdToEdit) {
          return asset;
        }
        return a;
      });
    } else {
      traverseToAsset(updatedProject.assets, chainOfCustody, childAsset => {
        childAsset.assets = childAsset.assets.map((subordinateAsset: Asset) => {
          if (subordinateAsset.id === assetIdToEdit) {
            return asset;
          }
          return subordinateAsset;
        });
      });
    }
    setProject(updatedProject);
  }

  function deleteAsset(assetId: string, chainOfCustody: string[]) {
    setPreviousProject(project);
    const updatedProject = JSON.parse(JSON.stringify(project));

    chainOfCustody = chainOfCustody.slice(0, -1);

    if (chainOfCustody.length === 0) {
      updatedProject.assets.forEach((asset: Asset, index: number) => {
        if (asset.id === assetId) updatedProject.assets.splice(index, 1);
      });
    } else {
      traverseToAsset(updatedProject.assets, chainOfCustody, asset => {
        asset.assets.forEach((subordinateAsset: Asset, index: number) => {
          if (subordinateAsset.id === assetId) asset.assets.splice(index, 1);
        });
      });
    }
    setProject(updatedProject);
  }

  function addEndPoint(newEndPoint: EndPoint, chainOfCustody: string[]) {
    setPreviousProject(project);
    const updatedProject = JSON.parse(JSON.stringify(project));
    traverseToAsset(updatedProject.assets, chainOfCustody, asset => {
      asset.monitoring.push(newEndPoint);
    });
    setProject(updatedProject);
  }

  function deleteEndPoint(endpointId: string, chainOfCustody: string[]) {
    setPreviousProject(project);
    const updatedProject = JSON.parse(JSON.stringify(project));
    traverseToAsset(updatedProject.assets, chainOfCustody, asset => {
      asset.monitoring.forEach((endpoint: EndPoint, index: number) => {
        if (endpoint.id === endpointId) asset.monitoring.splice(index, 1);
      });
    });
    setProject(updatedProject);
  }

  function specEndPoint(
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) {
    setPreviousProject(project);
    const updatedProject = JSON.parse(JSON.stringify(project));
    traverseToAsset(updatedProject.assets, chainOfCustody, asset => {
      asset.monitoring.forEach((endpoint: EndPoint) => {
        if (endpoint.id === endpointId) {
          endpoint.specs = newEndPointSpec;
          endpoint.surveyed = true;
        }
      });
    });
    setProject(updatedProject);
  }

  function addNewAssetsAndEndPoints(
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) {
    setPreviousProject(project);
    const updatedProject = JSON.parse(JSON.stringify(project));
    if (chainOfCustody.length === 0) {
      updatedProject.assets.push(...newAssets);
      // updatedProject.monitoring.push(...newEndPoints);
      updatedProject.meta = { ...updatedProject.meta, ...meta };
    } else {
      traverseToAsset(updatedProject.assets, chainOfCustody, asset => {
        asset.assets.push(...newAssets);
        asset.monitoring.push(...newEndPoints);
        asset.surveyed = surveyed;
        asset.meta = { ...asset.meta, ...meta };
      });
    }
    setProject(updatedProject);
  }

  function setSnapAddress(chainOfCustody: string[], endpointId: string, snapaddr?: string | null) {
    setPreviousProject(project);
    const updatedProject = JSON.parse(JSON.stringify(project));
    traverseToAsset(updatedProject.assets, chainOfCustody, asset => {
      asset.monitoring.forEach((endpoint: EndPoint) => {
        if (endpoint.id === endpointId) {
          endpoint.snapaddr = snapaddr;
        }
      });
    });
    setProject(updatedProject);
  }

  useEffect(() => {
    if (!previousProject) {
      // Avoid automatic save-back on first loading of the project.
      return;
    }
    axios
      .put(PUT_PROJECT_URL, project)
      .then(response => {})
      .catch(() => setProject(previousProject));
  }, [axios, previousProject, project]);

  useEffect(() => {
    axios.get(GET_PROJECT_URL(params.projectId!)).then(response => {
      setProject(response.data);
    });
  }, [axios, params.projectId]);

  useEffect(() => {
    if (!project) return;
    if (!project.siteId) return;

    axios.get(GET_SITE_URL(project.siteId)).then(response => {
      setSite(response.data);
    });
  }, [axios, project]);

  const assetNameSet = useMemo(() => {
    if (project === null) {
      return new Set<string>();
    }
    return transformProjectToAssetNameSet(project);
  }, [project]);

  const addAssetAndHideForm = (asset: Asset, chainOfCustody: string[]) => {
    addAsset(asset, chainOfCustody);
    setShouldShowNewAssetForm(false);
  };

  if (project) {
    return (
      <Page>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '24px 0 16px',
          }}
        >
          <div>
            {site && (
              <Link to={`/site/${site.id}`} style={{ textDecoration: 'none' }}>
                <Typography variant="caption" sx={{ color: 'primary.main' }}>
                  {site.name}
                </Typography>
              </Link>
            )}
            <Typography variant="h4" gutterBottom>
              {project.name}
            </Typography>
          </div>
          <div>
            <Fab
              variant="extended"
              color="default"
              size="medium"
              style={{ marginRight: '1rem' }}
              onClick={() => {
                axios.get(GET_WORKBOOK_URL(params.projectId!)).then(response => {
                  window.open(response.data.url, '_blank');
                });
              }}
            >
              <Download sx={{ mr: 1 }} />
              Build Work Package
            </Fab>

            <Fab
              variant="extended"
              color="secondary"
              size="medium"
              onClick={() => setShouldShowNewAssetForm(true)}
            >
              <Add sx={{ mr: 1 }} />
              Add New Asset
            </Fab>
          </div>
        </div>

        <ToggleButtonGroup
          value={mode}
          exclusive
          onChange={(event, newMode) => setMode(newMode)}
          aria-label="text alignment"
          style={{ marginBottom: '24px' }}
        >
          <ToggleButton value={ProjectMode.SURVEY}>Survey</ToggleButton>
          <ToggleButton value={ProjectMode.SPEC}>Spec</ToggleButton>
          <ToggleButton value={ProjectMode.COMMISSION}>Commission</ToggleButton>
        </ToggleButtonGroup>

        {shouldShowNewAssetForm ? (
          <NewAssetComponent
            chainOfCustody={[]}
            addAsset={addAssetAndHideForm}
            onCancel={() => setShouldShowNewAssetForm(false)}
            assetNameSet={assetNameSet}
          />
        ) : (
          <></>
        )}

        {mode === ProjectMode.SPEC &&
          flattenedAssets.map(asset => (
            <FlatAssetCard
              key={asset.id}
              asset={asset}
              addNewAssetsAndEndPoints={addNewAssetsAndEndPoints}
              specEndPoint={specEndPoint}
            />
          ))}

        {mode === ProjectMode.SPEC &&
          flattenedEndpointsThatNeedToBeSpecd.map(endpoint => (
            <FlatEndpointCard
              key={endpoint.id}
              endpoint={endpoint}
              addNewAssetsAndEndPoints={addNewAssetsAndEndPoints}
              specEndPoint={specEndPoint}
              mode={mode}
              saveSnapAddress={snapaddr =>
                setSnapAddress(endpoint.chainOfCustody, endpoint.id, snapaddr)
              }
            />
          ))}

        {mode === ProjectMode.COMMISSION &&
          flattenedEndPoints.map(endpoint => (
            <FlatEndpointCard
              key={endpoint.id}
              endpoint={endpoint}
              addNewAssetsAndEndPoints={addNewAssetsAndEndPoints}
              specEndPoint={specEndPoint}
              mode={mode}
              saveSnapAddress={snapaddr =>
                setSnapAddress(endpoint.chainOfCustody, endpoint.id, snapaddr)
              }
            />
          ))}

        {mode === ProjectMode.SURVEY &&
          project.assets.map(asset => {
            return (
              <AssetComponent
                key={asset.id}
                asset={asset}
                chainOfCustody={[asset.id]}
                addAsset={addAsset}
                editAsset={editAsset}
                deleteAsset={deleteAsset}
                addEndPoint={addEndPoint}
                deleteEndPoint={deleteEndPoint}
                addNewAssetsAndEndPoints={addNewAssetsAndEndPoints}
                specEndPoint={specEndPoint}
                assetNameSet={assetNameSet}
              />
            );
          })}
      </Page>
    );
  }

  return <LoadingIndicator />;
}
