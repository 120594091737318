import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType, FC } from 'react';
import LoadingIndicator from './LoadingIndicator';

interface ProtectedRouteProps {
  component: ComponentType;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingIndicator />,
  });
  return <Component />;
};
