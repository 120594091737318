import { useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import { useState, useEffect } from 'react';
import { PROJECTS_INDEX_URL } from '../const';
import { Project } from '../Models';
import { useAxios } from '../libs/axios';
import LoadingIndicator from '../LoadingIndicator';
import SiteImporter from '../SiteImporter';
import Page from '../Page';
import ProjectCard from './ProjectCard';

export default function ProjectsPage() {
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const { axios } = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(PROJECTS_INDEX_URL).then(response => {
      setProjects(response.data);
    });
  }, [axios]);

  if (projects) {
    return (
      <>
        {showImportDialog && <SiteImporter onClose={() => setShowImportDialog(false)} />}
        <Fab
          color="secondary"
          aria-label="add"
          style={{ position: 'fixed', bottom: '32px', right: '32px' }}
          onClick={() => navigate('/projects/new')}
        >
          <span className="material-icons">add</span>
        </Fab>
        <Fab
          aria-label="import"
          style={{ position: 'fixed', bottom: '104px', right: '40px' }}
          size="small"
          onClick={() => setShowImportDialog(true)}
        >
          <span className="material-icons">import_export</span>
        </Fab>
        <Page>
          {projects.map(project => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </Page>
      </>
    );
  }

  return <LoadingIndicator />;
}
