import { Divider, List } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { actionForAssetType } from './Actions';
import EditAssetComponent from './EditAssetComponent';
import EndPointIndicator from './EndPointIndicator';
import {
  Asset,
  ASSET_TYPE_COMMISSIONED,
  ASSET_TYPE_MAP,
  EndPoint,
  EndPointSpec,
  JSONObject,
} from './Models';
import NewAssetComponent from './NewAssetComponent';
import NewEndPointComponent from './NewEndPointComponent';
import { Questionnaire } from './QuestionnaireComponent';

export default function AssetComponent(props: {
  asset: Asset;
  chainOfCustody: string[];
  addAsset: (asset: Asset, chainOfCustody: string[]) => void;
  editAsset: (asset: Asset, chainOfCustody: string[]) => void;
  deleteAsset: (assetId: string, chainOfCustody: string[]) => void;
  addEndPoint: (endpoint: EndPoint, chainOfCustody: string[]) => void;
  deleteEndPoint: (endpointId: string, chainOfCustody: string[]) => void;
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void;
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void;
  assetNameSet: Set<string>;
}) {
  const [shouldShowNewAssetForm, setShouldShowNewAssetForm] = useState(false);
  const [shouldShowEditAssetForm, setShouldShowEditAssetForm] = useState(false);
  const [shouldShowNewEndPointForm, setShouldShowNewEndPointForm] = useState(false);
  const [shouldShowSpecModal, setShouldShowSpecModal] = useState(false);

  const addAsset = (asset: Asset, chainOfCustody: string[]) => {
    props.addAsset(asset, chainOfCustody);
    setShouldShowNewAssetForm(false);
  };

  const editAsset = (asset: Asset, chainOfCustody: string[]) => {
    props.editAsset(asset, chainOfCustody);
    setShouldShowEditAssetForm(false);
  };

  const addEndPoint = (endpoint: EndPoint, chainOfCustody: string[]) => {
    props.addEndPoint(endpoint, chainOfCustody);
    setShouldShowNewEndPointForm(false);
  };

  return (
    <>
      <Accordion elevation={2} style={{ backgroundColor: 'rgba(0,0,0,0.06)' }}>
        <AccordionSummary expandIcon={<span className="material-icons">expand_more</span>}>
          <Typography sx={{ width: '33%', flexShrink: 0 }}>{props.asset.name}</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {ASSET_TYPE_MAP.get(props.asset.type)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {shouldShowNewAssetForm ? (
            <NewAssetComponent
              chainOfCustody={props.chainOfCustody}
              addAsset={addAsset}
              onCancel={() => setShouldShowNewAssetForm(false)}
              assetNameSet={props.assetNameSet}
            />
          ) : (
            <></>
          )}
          {shouldShowEditAssetForm ? (
            <EditAssetComponent
              asset={props.asset}
              chainOfCustody={props.chainOfCustody}
              editAsset={editAsset}
              onCancel={() => setShouldShowEditAssetForm(false)}
              assetNameSet={props.assetNameSet}
            />
          ) : (
            <></>
          )}
          {shouldShowNewEndPointForm ? (
            <NewEndPointComponent
              chainOfCustody={props.chainOfCustody}
              addEndPoint={addEndPoint}
              onCancel={() => setShouldShowNewEndPointForm(false)}
            />
          ) : (
            <></>
          )}
          <Divider />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 0px',
            }}
          >
            <Typography color="text.secondary" display="block" variant="subtitle2">
              Child Assets
            </Typography>
            <Button onClick={() => setShouldShowNewAssetForm(true)}>Add Child Asset</Button>
          </div>
          {props.asset.assets.map((asset: Asset) => {
            return (
              <AssetComponent
                key={asset.id}
                asset={asset}
                chainOfCustody={[...props.chainOfCustody, asset.id]}
                addAsset={props.addAsset}
                editAsset={props.editAsset}
                deleteAsset={props.deleteAsset}
                addEndPoint={props.addEndPoint}
                deleteEndPoint={props.deleteEndPoint}
                addNewAssetsAndEndPoints={props.addNewAssetsAndEndPoints}
                specEndPoint={props.specEndPoint}
                assetNameSet={props.assetNameSet}
              />
            );
          })}
          {props.asset.assets.length === 0 && (
            <Typography
              color="text.secondary"
              display="block"
              variant="body2"
              style={{ margin: '8px 0px 0px', fontStyle: 'italic' }}
            >
              No child assets have been added yet.
            </Typography>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 0px',
              margin: '16px 0 0',
            }}
          >
            <Typography color="text.secondary" display="block" variant="subtitle2">
              Monitoring Endpoints
            </Typography>
            <Button onClick={() => setShouldShowNewEndPointForm(true)}>
              Add Monitoring Endpoint
            </Button>
          </div>
          <List
            style={{
              backgroundColor: '#4b4b4b',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
              borderRadius: '2px',
            }}
            dense={true}
          >
            {props.asset.monitoring.map((endpoint: EndPoint) => {
              return (
                <EndPointIndicator
                  key={endpoint.id}
                  chainOfCustody={props.chainOfCustody}
                  endpoint={endpoint}
                  deleteEndPoint={props.deleteEndPoint}
                />
              );
            })}
          </List>
          {props.asset.monitoring.length === 0 && (
            <Typography
              color="text.secondary"
              display="block"
              variant="body2"
              style={{ margin: '16px 0', fontStyle: 'italic' }}
            >
              No monitoring endpoints have been added.
            </Typography>
          )}
          <Divider />
          {props.asset.type !== ASSET_TYPE_COMMISSIONED && (
            <>
              <Button
                color="primary"
                onClick={() => setShouldShowSpecModal(true)}
                style={{ margin: '16px 0 0' }}
                disabled={props.asset.surveyed}
              >
                Spec Asset
              </Button>
              <Button
                color="secondary"
                onClick={() => setShouldShowEditAssetForm(true)}
                style={{ margin: '16px 0 0' }}
              >
                Edit Asset
              </Button>
              <Button
                color="error"
                onClick={() => props.deleteAsset(props.asset.id, props.chainOfCustody)}
                style={{ margin: '16px 0 0' }}
              >
                Delete Asset
              </Button>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      {shouldShowSpecModal && (
        <Questionnaire
          projectNode={props.asset}
          chainOfCustody={props.chainOfCustody}
          valuesCallback={values => {
            actionForAssetType(
              props.asset,
              props.chainOfCustody,
              props.addNewAssetsAndEndPoints,
              props.specEndPoint
            )(values);

            setShouldShowSpecModal(false);
          }}
          onClose={() => setShouldShowSpecModal(false)}
        />
      )}
    </>
  );
}
