import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { PUT_PROJECT_URL, SITES_INDEX_URL } from '../const';
import FormLayout from '../FormLayout';
import { useAxios } from '../libs/axios';
import { Project, Site } from '../Models';
import LoadingIndicator from '../LoadingIndicator';
import Page from '../Page';

export default function NewProjectComponent() {
  const { axios } = useAxios();
  const navigate = useNavigate();
  const [sites, setSites] = useState<Site[]>([]);
  const [isLoadingSites, setIsLoadingSites] = useState(false);

  useEffect(() => {
    axios.get(SITES_INDEX_URL).then(response => {
      setSites(response.data);
      setIsLoadingSites(false);
    });
  }, [axios]);

  if (isLoadingSites) {
    return <LoadingIndicator />;
  }

  return (
    <Page>
      <Card sx={{ maxWidth: 345 }} elevation={2} style={{ margin: '0 auto' }}>
        <CardContent>
          <Formik
            initialValues={{ name: '', site: '' }}
            validationSchema={Yup.object({
              name: Yup.string().min(1).required('Required'),
              site: Yup.string().min(1).required('Required'),
            })}
            onSubmit={values => {
              const project: Project = {
                id: uuidv4(),
                name: values.name,
                siteId: values.site,
                assets: [],
              };

              axios.put(PUT_PROJECT_URL, project).then(response => {
                navigate(`/project/${response.data.id}`);
              });
            }}
          >
            {formik => (
              <Form>
                <FormLayout>
                  <Typography variant="h6">Add Project</Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    name="name"
                    label="Project Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />

                  <FormControl fullWidth>
                    <InputLabel>Site</InputLabel>
                    <Select
                      label="Site"
                      name="site"
                      value={formik.values.site}
                      onChange={formik.handleChange}
                      error={formik.touched.site && Boolean(formik.errors.site)}
                      variant="filled"
                    >
                      {sites.map(site => {
                        return (
                          <MenuItem key={site.id} value={site.id}>
                            {site.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{formik.touched.site && formik.errors.site}</FormHelperText>
                  </FormControl>

                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    Save Project
                  </Button>
                </FormLayout>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Page>
  );
}
