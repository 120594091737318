import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAxios } from '../libs/axios';
import { PUT_SITE_URL } from '../const';
import { EscalationPath, Site } from '../Models';

export default function DeleteEscalationPathModal({
  site,
  path,
  onClose,
  onSave,
}: {
  onClose: () => void;
  onSave: (site: Site) => void;
  site: Site;
  path: EscalationPath;
}) {
  const { axios } = useAxios();

  function deletePath() {
    const updatedSite: Site = {
      ...site,
      escalationPaths: site.escalationPaths.filter(otherPath => otherPath.id !== path.id),
    };

    axios.put(PUT_SITE_URL, updatedSite).then(response => {
      onSave(response.data);
    });
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Delete {path.name}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove this escalation path?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={deletePath}>
          Delete Escalation Path
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
