import { AxiosInstance } from 'axios';
import { createContext } from 'react';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AxiosContext>.');
};

export interface AxiosContextInterface {
  axios: AxiosInstance;
}

const initialContext: AxiosContextInterface = {
  axios: stub as unknown as AxiosInstance,
};

export const AxiosContext = createContext<AxiosContextInterface>(initialContext);
