import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useAxios } from '../libs/axios';
import { PUT_SITE_URL } from '../const';
import FormLayout from '../FormLayout';
import { Personnel, Site } from '../Models';

export default function EditPersonnelModal({
  site,
  person,
  onClose,
  onSave,
}: {
  onClose: () => void;
  onSave: (site: Site) => void;
  site: Site;
  person: Personnel;
}) {
  const { axios } = useAxios();

  return (
    <Formik
      initialValues={{
        name: person.name,
        email: person.email,
        phone: person.phone,
        receivesReports: Boolean(person.receivesReports),
      }}
      validationSchema={Yup.object({
        name: Yup.string().min(1).required('Required'),
        email: Yup.string()
          .email()
          .required('Required')
          .test('unique', 'Email already exists', value => {
            const email = value?.trim()?.toLocaleLowerCase();

            return !site.personnel.some(
              otherPerson =>
                otherPerson.id !== person.id && otherPerson.email.toLocaleLowerCase() === email
            );
          }),
        phone: Yup.string().notRequired(),
      })}
      onSubmit={async values => {
        const updatedPerson: Personnel = {
          id: uuidv4(),
          name: values.name,
          email: values.email,
          phone: values.phone,
          receivesReports: values.receivesReports,
        };

        const updatedSite: Site = {
          ...site,
          personnel: site.personnel.map(otherPerson => {
            if (otherPerson.id === person.id) {
              return updatedPerson;
            }

            return otherPerson;
          }),
        };

        await axios.put(PUT_SITE_URL, updatedSite).then(response => {
          onSave(response.data);
        });
      }}
    >
      {formik => (
        <Dialog open={true} onClose={onClose}>
          <Form>
            <DialogTitle>Edit {person.name}</DialogTitle>
            <DialogContent style={{ width: '280px' }}>
              <FormLayout>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  margin="dense"
                  id="phone"
                  label="Phone"
                  type="phone"
                  fullWidth
                  variant="standard"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
                <FormControlLabel
                  control={<Checkbox checked={formik.values.receivesReports} />}
                  label="Receives Reports"
                  name="receivesReports"
                  onChange={formik.handleChange}
                />
              </FormLayout>
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit" disabled={formik.isSubmitting}>
                Save Person
              </Button>
              <Button onClick={onClose} color="warning">
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
