import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form } from 'formik';
import ReactTooltip from 'react-tooltip';
import * as Yup from 'yup';
import { Asset, EndPoint } from './Models';
import {
  YesNoQuestion,
  DropDownQuestion,
  NumberQuestion,
  EmailQuestion,
  NotesQuestion,
  PercentQuestion,
  SnapAddressQuestion,
} from './QuestionComponents';
import { QuestionDefinition, questionsForType } from './Questions';

const SNAP_ADDRESS_REGEX =
  /^(00[.:]?1[cC][.:]?2[cC][.:]?[0-9a-fA-F]{2}[.:]?[0-9a-fA-F]{2}[.:]?)?([0-9a-fA-F][.:]?){5}[0-9a-fA-F]$/;

export function initialValuesFor(questions: QuestionDefinition[]) {
  const initialValues: { [key: string]: string | number } = {};

  questions.forEach(question => {
    initialValues[question.fieldName] = question.default;
  });

  return initialValues;
}

export function validationFor(questions: QuestionDefinition[]) {
  return questions.reduce((validations, question) => {
    switch (question.format) {
      case DropDownQuestion: {
        return {
          ...validations,
          [question.fieldName]: Yup.string().oneOf(question.options).required(),
        };
      }
      case EmailQuestion: {
        return {
          ...validations,
          [question.fieldName]: Yup.string().email().required('Required'),
        };
      }
      case YesNoQuestion: {
        return {
          ...validations,
          [question.fieldName]: Yup.string().oneOf(['Yes', 'No']).required('Required'),
        };
      }
      case NotesQuestion: {
        return { ...validations, [question.fieldName]: Yup.string() };
      }
      case NumberQuestion: {
        return {
          ...validations,
          [question.fieldName]: Yup.number().min(0).required('Required'),
        };
      }
      case PercentQuestion: {
        return {
          ...validations,
          [question.fieldName]: Yup.number().min(0).max(100).required('Required'),
        };
      }
      case SnapAddressQuestion: {
        return {
          ...validations,
          [question.fieldName]: Yup.string().test('Valid Snap Address', value => {
            if (!value) return true;

            return SNAP_ADDRESS_REGEX.test(value || '');
          }),
        };
      }
      default: {
        throw new Error(`Could not build validation for ${question}`);
      }
    }
  }, {} as { [key: string]: Yup.StringSchema | Yup.NumberSchema });
}

export function Questionnaire(props: {
  projectNode: Asset | EndPoint;
  chainOfCustody: string[];
  valuesCallback: (values: any) => void;
  onClose: () => void;
}) {
  const questions = questionsForType(props.projectNode.type);

  if (questions.length <= 0) {
    return <></>;
  }

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Questionnaire</DialogTitle>

      <Formik
        initialValues={initialValuesFor(questions)}
        validationSchema={Yup.object(validationFor(questions))}
        onSubmit={props.valuesCallback}
      >
        {formik => (
          <Form>
            <DialogContent>
              {questions.map(question => {
                return (
                  <question.format
                    key={question.fieldName}
                    fieldName={question.fieldName}
                    text={question.text}
                    default={question.default}
                    tooltip={question.tooltip}
                    options={question.options}
                    formik={formik}
                  />
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button type="submit" disabled={formik.isSubmitting}>
                Submit
              </Button>
              <Button color="warning" onClick={props.onClose}>
                Cancel
              </Button>
            </DialogActions>
            <ReactTooltip />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
