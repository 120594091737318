import { useState } from 'react';
import {
  Asset,
  EndPoint,
  EndPointSpec,
  ENDPOINT_TYPES_MAP,
  FlattenedEndPoint,
  JSONObject,
  ProjectMode,
} from '../Models';
import { actionForEndPointType } from '../Actions';
import { Button, Card, CardHeader } from '@mui/material';
import { Questionnaire } from '../QuestionnaireComponent';
import SnapAddressModal from './SnapAddressModal';

export default function FlatEndpointCard(props: {
  endpoint: FlattenedEndPoint;
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void;
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void;
  saveSnapAddress: (snapaddr?: string | null) => void;
  mode: ProjectMode;
}) {
  const [showSpecModal, setShowSpecModal] = useState(false);
  const [showSnapAddressModal, setShowSnapAddressModal] = useState(false);

  return (
    <>
      <Card
        sx={{
          marginBottom: '16px',
          padding: '8px 16px 8px 8px',
          height: '72px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CardHeader
          title={props.endpoint.custodyName}
          titleTypographyProps={{ variant: 'caption' }}
          subheader={ENDPOINT_TYPES_MAP.get(props.endpoint.type)}
          subheaderTypographyProps={{ variant: 'subtitle1' }}
        />
        {props.mode === ProjectMode.SPEC && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setShowSpecModal(true);
            }}
          >
            Spec Endpoint
          </Button>
        )}
        {props.mode === ProjectMode.COMMISSION && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setShowSnapAddressModal(true);
            }}
          >
            {props.endpoint.snapaddr ? 'Edit' : 'Set'} Snap Address
          </Button>
        )}
      </Card>
      {showSpecModal && (
        <Questionnaire
          projectNode={props.endpoint}
          chainOfCustody={props.endpoint.chainOfCustody}
          valuesCallback={values => {
            actionForEndPointType(
              props.endpoint,
              props.endpoint.chainOfCustody,
              props.specEndPoint
            )(values);

            setShowSpecModal(false);
          }}
          onClose={() => setShowSpecModal(false)}
        />
      )}
      {showSnapAddressModal && (
        <SnapAddressModal
          snapaddr={props.endpoint.snapaddr}
          onSave={snapaddr => {
            props.saveSnapAddress(snapaddr);
            setShowSnapAddressModal(false);
          }}
          onClose={() => setShowSnapAddressModal(false)}
        />
      )}
    </>
  );
}
