import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormLayout from './FormLayout';
import { Asset } from './Models';

export default function EditAssetComponent(props: {
  asset: Asset;
  chainOfCustody: string[];
  editAsset: (asset: Asset, chainOfCustody: string[]) => void;
  onCancel: () => void;
  assetNameSet: Set<string>;
}) {
  return (
    <Modal open={true} onClose={props.onCancel}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Formik
          initialValues={props.asset}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(1)
              .required('Required')
              .test('unique', 'Name already exists', value => {
                return (
                  props.asset.name.toLowerCase().trim() === value?.toLowerCase().trim() ||
                  !props.assetNameSet.has(value?.toLowerCase().trim() ?? '')
                );
              }),
          })}
          onSubmit={values => {
            props.editAsset({ ...props.asset, name: values.name }, props.chainOfCustody);
          }}
        >
          {formik => (
            <Form>
              <FormLayout>
                <Typography variant="h6">Edit Asset</Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  name="name"
                  label="Asset Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  Save Asset
                </Button>
              </FormLayout>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
