import { Fab } from '@mui/material';
import { useEffect, useState } from 'react';
import { SITES_INDEX_URL } from '../const';
import { useAxios } from '../libs/axios';
import LoadingIndicator from '../LoadingIndicator';
import { Site } from '../Models';
import Page from '../Page';
import AddSiteModal from './AddSiteModal';
import SiteCard from './SiteCard';

export default function SitesPage() {
  const [sites, setSites] = useState<Site[] | null>(null);
  const [showAddSiteModal, setShowAddSiteModal] = useState(false);
  const { axios } = useAxios();

  useEffect(() => {
    axios.get(SITES_INDEX_URL).then(response => {
      setSites(response.data);
    });
  }, [axios]);

  if (!sites) return <LoadingIndicator />;

  return (
    <>
      <Fab
        color="secondary"
        aria-label="add"
        style={{ position: 'fixed', bottom: '32px', right: '32px' }}
        onClick={() => setShowAddSiteModal(true)}
      >
        <span className="material-icons">add</span>
      </Fab>
      <Page>
        {sites.map(site => (
          <SiteCard key={site.id} site={site} />
        ))}
      </Page>
      {showAddSiteModal && <AddSiteModal onClose={() => setShowAddSiteModal(false)} />}
    </>
  );
}
