import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from './logo.svg';

/**
 * React component that renders a top bar with:
 *  - a logo
 *  - a link to the Projects page
 *
 * And renders any children as the main content.
 */
export default function AppShell({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, logout } = useAuth0();

  function handleLogout() {
    logout({
      returnTo: `${window.location.origin}/`,
    });
  }

  return (
    <div className="AppShell">
      <header
        style={{
          maxWidth: '100vw',
          height: '64px',
          padding: '0px 16px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#131313',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100vw',
          zIndex: '1000',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img src={logo} alt="logo" style={{ height: '24px' }} />
          <div
            style={{
              margin: '0 16px',
              height: '32px',
              display: 'block',
              width: '1px',
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
            }}
          />
          <Typography variant="subtitle1">Survey Tool</Typography>
        </div>
        <div className="AppShell__header-links">
          <Button component={Link} to="/sites">
            Sites
          </Button>
          <Button component={Link} to="/projects">
            Projects
          </Button>
          {isAuthenticated && <Button onClick={handleLogout}>Log Out</Button>}
        </div>
      </header>
      <main className="AppShell__main">{children}</main>
    </div>
  );
}
