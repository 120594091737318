import { Asset, Project } from '../Models';

export function transformProjectToAssetNameSet(project: Project): Set<string> {
  const assetNameSet = new Set<string>();

  project.assets.forEach(asset => addNamesToSet(asset));

  function addNamesToSet(asset: Asset) {
    assetNameSet.add(asset.name.toLowerCase().trim());
    asset.assets.forEach(child => {
      addNamesToSet(child);
    });
  }

  return assetNameSet;
}
