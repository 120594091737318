import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useAxios } from './libs/axios';
import * as SSC from './services/ssc.service';
import { PUT_PROJECT_URL, PUT_SITE_URL, SITES_INDEX_URL } from './const';
import { toFpoAssetsFromSscAssets } from './transformers/ssc-to-fpo';
import FormLayout from './FormLayout';
import { Project, Site } from './Models';

export default function SiteImporter(props: { onClose: () => void }) {
  const { axios } = useAxios();
  const { user, getAccessTokenSilently } = useAuth0();
  const [isLoadingSscSites, setIsLoadingSscSites] = useState(false);
  const [isLoadingFpoSites, setIsLoadingFpoSites] = useState(false);
  const [sscSites, setSscSites] = useState<SSC.SscSite[]>([]);
  const [fpoSites, setFpoSites] = useState<Site[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    getAccessTokenSilently().then(async token => {
      const sites = await SSC.getMySites(token);

      setIsLoadingSscSites(false);
      setSscSites(sites);
    });
  }, [user, getAccessTokenSilently]);

  useEffect(() => {
    axios.get(SITES_INDEX_URL).then(response => {
      setFpoSites(response.data);
      setIsLoadingFpoSites(false);
    });
  }, [axios]);

  if (isLoadingSscSites || isLoadingFpoSites) {
    return null;
  }

  return (
    <Formik
      initialValues={{ name: '', site: '' }}
      validationSchema={Yup.object({
        name: Yup.string().min(1).required('Required'),
        site: Yup.string().min(1).required('Required'),
      })}
      onSubmit={async values => {
        const token = await getAccessTokenSilently();
        const assetsForSite = await SSC.getAssetsForSite(token, values.site);
        const sscSite = sscSites.find(site => site.id === values.site);
        const projectId = uuidv4();

        if (!sscSite) return;

        let fpoSite: Site | undefined = fpoSites.find(fpoSite => {
          return fpoSite.name === sscSite.name;
        });

        if (!fpoSite) {
          fpoSite = {
            id: uuidv4(),
            name: sscSite.name,
            personnel: [],
            projectIds: [projectId],
            escalationPaths: [],
          };

          await axios.put(PUT_SITE_URL, fpoSite);
        }

        const project: Project = {
          id: projectId,
          name: values.name,
          siteId: fpoSite.id,
          assets: toFpoAssetsFromSscAssets(assetsForSite),
        };

        await axios.put(PUT_PROJECT_URL, project).then(response => {
          navigate(`/project/${response.data.id}`);
        });
      }}
    >
      {formik => (
        <Dialog open={true} onClose={props.onClose}>
          <Form>
            <DialogTitle>Import Site</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To import a SimplySnap Cloud site, select a site you have access to and give your
                new project a name
              </DialogContentText>
              <FormLayout>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Project Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <FormControl fullWidth>
                  <InputLabel>Site</InputLabel>
                  <Select
                    label="Site"
                    name="site"
                    value={formik.values.site}
                    onChange={formik.handleChange}
                    error={formik.touched.site && Boolean(formik.errors.site)}
                    variant="filled"
                  >
                    {sscSites.map(site => {
                      return (
                        <MenuItem key={site.id} value={site.id}>
                          {site.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{formik.touched.site && formik.errors.site}</FormHelperText>
                </FormControl>
              </FormLayout>
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit" disabled={formik.isSubmitting}>
                Import Project
              </Button>
              <Button onClick={props.onClose} color="warning">
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
