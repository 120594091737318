import { ListItem, IconButton, ListItemText, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { EndPoint, ENDPOINT_TYPES_MAP, ENDPOINT_TYPE_COMMISSIONED } from './Models';

export default function EndPointIndicator(props: {
  endpoint: EndPoint;
  chainOfCustody: string[];
  deleteEndPoint: (endpointId: string, chainOfCustody: string[]) => void;
}) {
  return (
    <ListItem
      secondaryAction={
        props.endpoint.type !== ENDPOINT_TYPE_COMMISSIONED ? (
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => props.deleteEndPoint(props.endpoint.id, props.chainOfCustody)}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <Typography variant="overline" display="block" gutterBottom>
            Commissioned
          </Typography>
        )
      }
      style={{ borderBottom: '1px solid rgba(255, 255, 255,0.06)' }}
    >
      <ListItemText
        primary={
          props.endpoint.type === ENDPOINT_TYPE_COMMISSIONED
            ? props.endpoint.meta.name
            : ENDPOINT_TYPES_MAP.get(props.endpoint.type)
        }
      />
    </ListItem>
  );
}
