import { Card, CardHeader, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Project } from '../Models';

export default function ProjectCard(props: { project: Project }) {
  const navigate = useNavigate();

  return (
    <Card sx={{ marginBottom: '16px', paddingRight: '8px' }}>
      <CardHeader
        avatar={<span className="material-icons">assignment</span>}
        action={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/project/${props.project.id}`)}
          >
            Visit Project
          </Button>
        }
        title={props.project.name}
        titleTypographyProps={{ variant: 'subtitle1' }}
      />
    </Card>
  );
}
