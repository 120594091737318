import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig } from 'axios';
import { ReactNode, useEffect, useMemo } from 'react';
import { AxiosContext } from './axios-context';

interface Props {
  children: ReactNode;
  config: AxiosRequestConfig;
}

export default function AxiosProvider(props: Props) {
  const { children, config } = props;
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = useMemo(() => axios.create(config), [config]);

  useEffect(() => {
    axiosInstance.interceptors.request.use(
      async config => {
        const token = await getAccessTokenSilently();
        return {
          ...config,
          headers: {
            ...config.headers,
            // todo: use Auth0 access token
            Authorization: `Bearer ${token}`,
            //Authorization: 'QDhRCDTWByWLINJSiO01O-kZA4Fde01INLwFU679LCk=',
          },
        };
      },
      error => {
        return Promise.reject(error);
      }
    );
  }, [axiosInstance.interceptors.request, getAccessTokenSilently]);

  const contextValue = useMemo(
    () => ({
      axios: axiosInstance,
    }),
    [axiosInstance]
  );

  return <AxiosContext.Provider value={contextValue}>{children}</AxiosContext.Provider>;
}
