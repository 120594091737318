/**
 * Renders a page with 24px of padding and a max width of 1196px centered.
 */
export default function Page(props: { children: React.ReactNode }) {
  return (
    <div
      style={{
        padding: '96px 24px 24px',
        maxWidth: '1196px',
        margin: '0 auto',
      }}
    >
      {props.children}
    </div>
  );
}
