import { Formik, Form } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { Asset, AssetTypeUnion, ASSET_TYPES } from './Models';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import FormLayout from './FormLayout';

export default function NewAssetComponent(props: {
  chainOfCustody: string[];
  addAsset: (asset: Asset, chainOfCustody: string[]) => void;
  onCancel: () => void;
  assetNameSet: Set<string>;
}) {
  return (
    <Modal open={true} onClose={props.onCancel}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Formik
          initialValues={{ name: '', type: 'MOTOR' as AssetTypeUnion }}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(1)
              .required('Required')
              .test('unique', 'Name already exists', value => {
                return !props.assetNameSet.has(value?.toLowerCase() ?? '');
              }),
            type: Yup.string().min(1).required('Required'),
          })}
          onSubmit={values => {
            props.addAsset(
              {
                ...values,
                assets: [],
                monitoring: [],
                id: uuidv4(),
                surveyed: false,
                meta: {},
              },
              props.chainOfCustody
            );
          }}
        >
          {formik => (
            <Form>
              <FormLayout>
                <Typography variant="h6">Add Asset</Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  name="name"
                  label="Asset Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <FormControl fullWidth>
                  <InputLabel>Monitoring Type</InputLabel>
                  <Select
                    label="Monitoring Type"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    variant="filled"
                  >
                    {ASSET_TYPES.map(type => {
                      return (
                        <MenuItem key={type['id']} value={type['id']}>
                          {type['display']}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{formik.touched.type && formik.errors.type}</FormHelperText>
                </FormControl>

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  Save Asset
                </Button>
              </FormLayout>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
