import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAxios } from '../libs/axios';
import { PUT_SITE_URL } from '../const';
import { Personnel, Site } from '../Models';

export default function DeletePersonnelModal({
  site,
  person,
  onClose,
  onSave,
}: {
  onClose: () => void;
  onSave: (site: Site) => void;
  site: Site;
  person: Personnel;
}) {
  const { axios } = useAxios();

  function deletePerson() {
    const updatedSite: Site = {
      ...site,
      personnel: site.personnel.filter(otherPerson => otherPerson.id !== person.id),
    };

    axios.put(PUT_SITE_URL, updatedSite).then(response => {
      onSave(response.data);
    });
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Delete {person.name}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Removing this person from the site will also remove any escalation paths this user is
          associated with. Are you sure you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={deletePerson}>
          Delete Person
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
