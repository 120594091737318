/**
 * Applies CSS to put the children of a `<FormLayout>` in
 * a row grid and center them with 24px of padding.
 */
export default function FormLayout(props: { children: React.ReactNode }) {
  return (
    <div
      style={{
        display: "grid",
        width: "100%",
        gridGap: "24px",
        gridTemplateColumns: "1fr",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.children}
    </div>
  );
}
