export const ENDPOINT_TYPE_COMPRESSED_AIR_FLOW = 'COMPRESSED_AIR_FLOW';
export const ENDPOINT_TYPE_COMPRESSED_AIR_PRESSURE = 'COMPRESSED_AIR_PRESSURE';
export const ENDPOINT_TYPE_COMPRESSED_AIR_TEMP = 'COMPRESSED_AIR_TEMP';
export const ENDPOINT_TYPE_DIFFERENTIAL_PRESSURE = 'DIFFERENTIAL_PRESSURE';
export const ENDPOINT_TYPE_MACHINE_HEALTH = 'MACHINE_STATE_HEALTH';
export const ENDPOINT_TYPE_PARTICLE_SENSING = 'PARTICLE_SENSING';
export const ENDPOINT_TYPE_POWER = 'POWER';
export const ENDPOINT_TYPE_SILO_LEVEL = 'SILO_LEVEL';
export const ENDPOINT_TYPE_CLIMATE_CONTROL = 'CLIMATE_CONTROL';
export const ENDPOINT_TYPE_COMMISSIONED = 'COMMISSIONED';

export const ENDPOINT_TYPES = [
  { id: ENDPOINT_TYPE_POWER, display: 'Power' },
  { id: ENDPOINT_TYPE_DIFFERENTIAL_PRESSURE, display: 'Differential Pressure' },
  { id: ENDPOINT_TYPE_MACHINE_HEALTH, display: 'Machine Health' },
  { id: ENDPOINT_TYPE_SILO_LEVEL, display: 'Silo Level Sensing' },
  { id: ENDPOINT_TYPE_PARTICLE_SENSING, display: 'Particle Sensing' },
  { id: ENDPOINT_TYPE_COMPRESSED_AIR_FLOW, display: 'Compressed Air Flow' },
  { id: ENDPOINT_TYPE_COMPRESSED_AIR_PRESSURE, display: 'Compressed Air Pressure' },
  { id: ENDPOINT_TYPE_COMPRESSED_AIR_TEMP, display: 'Compressed Air Temperature' },
  { id: ENDPOINT_TYPE_COMMISSIONED, display: 'Commissioned' },
  { id: ENDPOINT_TYPE_CLIMATE_CONTROL, display: 'Climate Controls' },
] as const;

export type EndpointTypeUnion = typeof ENDPOINT_TYPES[number]['id'];

export const ENDPOINT_TYPES_MAP = new Map(ENDPOINT_TYPES.map(type => [type.id, type.display]));

export const ASSET_TYPE_FILTERSENSE_BPAC = 'FILTERSENSE_BPAC';
export const ASSET_TYPE_3_PHASE_TRANSFORMER = '3_PHASE_TRANSFORMER';
export const ASSET_TYPE_3_PHASE_TRANSFORMER_GROUP = 'TRANSFORMER_GROUP';
export const ASSET_TYPE_BAGHOUSE = 'BAGHOUSE';
export const ASSET_TYPE_BEARING = 'BEARING';
export const ASSET_TYPE_CIRCUIT_PANEL = 'CIRCUIT_PANEL';
export const ASSET_TYPE_COMPRESSOR = 'COMPRESSOR';
export const ASSET_TYPE_CONVEYOR = 'CONVEYOR';
export const ASSET_TYPE_FURNACE = 'FURNACE';
export const ASSET_TYPE_MAU = 'MAU';
export const ASSET_TYPE_METER_ELECTRICAL = 'METER_ELECTRIC';
export const ASSET_TYPE_MOTOR = 'MOTOR';
export const ASSET_TYPE_POWERTRAIN = 'POWERTRAIN';
export const ASSET_TYPE_RTU = 'RTU';
export const ASSET_TYPE_SILO = 'SILO';
export const ASSET_TYPE_SINGLE_PHASE_TRANSFORMER = 'SINGLE_PHASE_TRANSFORMER';
export const ASSET_TYPE_COMMISSIONED = 'COMMISSIONED';
export const ASSET_TYPE_CHILLER = 'CHILLER';
export const ASSET_TYPE_CNC = 'CNC';
export const ASSET_TYPE_CONDENSER = 'CONDENSER';
export const ASSET_TYPE_COOLING_TOWER = 'COOLING_TOWER';
export const ASSET_TYPE_CUPOLA = 'CUPOLA';
export const ASSET_TYPE_EXHAUST_FAN = 'EXHAUST_FAN';
export const ASSET_TYPE_EXTRUDER = 'EXTRUDER';
export const ASSET_TYPE_MAINS = 'MAINS';
export const ASSET_TYPE_SWITCH_GEAR = 'SWITCH_GEAR';
export const ASSET_TYPE_MIXER = 'MIXER';
export const ASSET_TYPE_MULLER = 'MULLER';
export const ASSET_TYPE_PAINT_BOOTH = 'PAINT_BOOTH';
export const ASSET_TYPE_PRESS = 'PRESS';
export const ASSET_TYPE_SCRUBBER = 'SCRUBBER';
export const ASSET_TYPE_SHOT_BLAST = 'SHOT_BLAST';
export const ASSET_TYPE_STAMPING_MACHINE = 'STAMPING_MACHINE';
export const ASSET_TYPE_WELDER = 'WELDER';
export const ASSET_TYPE_OTHER = 'OTHER';
export const ASSET_TYPE_NON_SCHEDULE_COMPRESSED_AIR_PIPE = 'NON_SCHEDULE_COMPRESSED_AIR_PIPE';
export const ASSET_TYPE_SCHEDULE_COMPRESSED_AIR_PIPE = 'SCHEDULE_COMPRESSED_AIR_PIPE';
export const ASSET_TYPE_NON_SCHEDULE_WATER_PIPE = 'NON_SCHEDULE_WATER_PIPE';
export const ASSET_TYPE_SCHEDULE_WATER_PIPE = 'SCHEDULE_WATER_PIPE';
export const ASSET_TYPE_NATURAL_GAS_PIPE = 'NATURAL_GAS_PIPE';
export const ASSET_TYPE_TANK = 'TANK';

export const ASSET_TYPES = [
  { id: ASSET_TYPE_CONVEYOR, display: 'Conveyor' },
  { id: ASSET_TYPE_BAGHOUSE, display: 'Baghouse' },
  { id: ASSET_TYPE_BEARING, display: 'Bearing' },
  { id: ASSET_TYPE_METER_ELECTRICAL, display: 'Meter (Electric)' },
  { id: ASSET_TYPE_MOTOR, display: 'Motor (AC)' },
  { id: ASSET_TYPE_POWERTRAIN, display: 'Powertrain' },
  { id: ASSET_TYPE_SILO, display: 'Silo' },
  { id: ASSET_TYPE_COMPRESSOR, display: 'Compressor' },
  { id: ASSET_TYPE_SINGLE_PHASE_TRANSFORMER, display: 'Transformer (Single Phase)' },
  { id: ASSET_TYPE_3_PHASE_TRANSFORMER, display: 'Transformer (3-Phase)' },
  { id: ASSET_TYPE_3_PHASE_TRANSFORMER_GROUP, display: 'Transformer Group (3 Single-Phases)' },
  { id: ASSET_TYPE_CIRCUIT_PANEL, display: 'Circuit Panel' },
  { id: ASSET_TYPE_FURNACE, display: 'Furnace' },
  { id: ASSET_TYPE_MAU, display: 'Make-up Air Unit' },
  { id: ASSET_TYPE_RTU, display: 'Roof Top Unit' },
  { id: ASSET_TYPE_COMMISSIONED, display: 'Commissioned' },
  { id: ASSET_TYPE_FILTERSENSE_BPAC, display: 'FilterSense BPAC' },
  { id: ASSET_TYPE_CHILLER, display: 'Chiller' },
  { id: ASSET_TYPE_CNC, display: 'CNC' },
  { id: ASSET_TYPE_CONDENSER, display: 'Condenser' },
  { id: ASSET_TYPE_COOLING_TOWER, display: 'Cooling Tower' },
  { id: ASSET_TYPE_CUPOLA, display: 'Cupola' },
  { id: ASSET_TYPE_EXHAUST_FAN, display: 'Exhaust Fan' },
  { id: ASSET_TYPE_EXTRUDER, display: 'Extruder' },
  { id: ASSET_TYPE_MAINS, display: 'Mains' },
  { id: ASSET_TYPE_SWITCH_GEAR, display: 'Switch Gear' },
  { id: ASSET_TYPE_MIXER, display: 'Mixer' },
  { id: ASSET_TYPE_MULLER, display: 'Muller' },
  { id: ASSET_TYPE_PAINT_BOOTH, display: 'Paint Booth' },
  { id: ASSET_TYPE_PRESS, display: 'Press' },
  { id: ASSET_TYPE_SCRUBBER, display: 'Scrubber' },
  { id: ASSET_TYPE_SHOT_BLAST, display: 'Shot Blast' },
  { id: ASSET_TYPE_STAMPING_MACHINE, display: 'Stamping Machine' },
  { id: ASSET_TYPE_WELDER, display: 'Welder' },
  { id: ASSET_TYPE_OTHER, display: 'Other' },
  { id: ASSET_TYPE_NON_SCHEDULE_WATER_PIPE, display: 'Non-Schedule Water Pipe' },
  { id: ASSET_TYPE_SCHEDULE_WATER_PIPE, display: 'Schedule Water Pipe' },
  { id: ASSET_TYPE_NON_SCHEDULE_COMPRESSED_AIR_PIPE, display: 'Non-Schedule Air Pipe' },
  { id: ASSET_TYPE_SCHEDULE_COMPRESSED_AIR_PIPE, display: 'Schedule Air Pipe' },
  { id: ASSET_TYPE_NATURAL_GAS_PIPE, display: 'Natural Gas Pipe' },
  { id: ASSET_TYPE_TANK, display: 'Tank' },
] as const;

export type AssetTypeUnion = typeof ASSET_TYPES[number]['id'];

export const ASSET_TYPE_MAP = new Map<string, string>(
  ASSET_TYPES.map(assetType => [assetType.id, assetType.display])
);

export interface Asset {
  id: string;
  name: string;
  type: AssetTypeUnion;
  assets: Asset[];
  monitoring: EndPoint[];
  surveyed: boolean;
  meta: JSONObject;
  sscId?: string | null;
}

export interface FlattenedAsset extends Asset {
  chainOfCustody: string[];
  custodyName: string;
}

export interface EndPoint {
  id: string;
  type: EndpointTypeUnion;
  specs: {};
  surveyed: boolean;
  meta: JSONObject;
  sscId?: string | null;
  snapaddr?: string | null;
}

export interface FlattenedEndPoint extends EndPoint {
  chainOfCustody: string[];
  custodyName: string;
  parentAsset: Asset;
}

export interface Project {
  id: string;
  name: string;
  assets: Asset[];
  siteId: string | null;
}

export interface EndPointSpec {
  id: string;
}

export interface PowerEndPointSpec extends EndPointSpec {
  voltage: number;
  phases: number;
  power: number;
  unit: string;
  amps: number;
  extrapolation: boolean;
  exposed: boolean;
  insulated: boolean;
}

export type JSONObject = { [key: string]: string | number | JSONObject };

export interface Personnel {
  id: string;
  name: string;
  email: string;
  phone: string;
  receivesReports: boolean;
}

export interface EscalationStep {
  personnelId: string;
  delayInMinutes: number;
}

export interface EscalationPath {
  id: string;
  name: string;
  steps: EscalationStep[];
}

export const MAX_ESCALATION_STEPS = 3;

export interface Site {
  id: string;
  name: string;
  personnel: Personnel[];
  projectIds: string[];
  escalationPaths: EscalationPath[];
}

export enum ProjectMode {
  SURVEY,
  SPEC,
  COMMISSION,
}
