import { v4 as uuidv4 } from 'uuid';

const BASE_URL = 'https://api.simplysnapsurvey.com/';

export const PUT_PROJECT_URL = `${BASE_URL}project`;
export const PROJECTS_INDEX_URL = `${BASE_URL}projects`;

export const GET_PROJECT_URL = (projectId: string) => `${BASE_URL}project/${projectId}`;
export const GET_WORKBOOK_URL = (projectId: string) => `${BASE_URL}workbook/${projectId}`;

export const PUT_SITE_URL = `${BASE_URL}site`;
export const SITES_INDEX_URL = `${BASE_URL}sites`;

export const GET_SITE_URL = (siteId: string) => `${BASE_URL}site/${siteId}`;

export const EQUIPMENT_TYPES = [];
export const MONITORING_ENDPOINT_TYPES = [];

export const POWER_UNIT_AMPS = 'Amps';

export function garbageReactKey() {
  return uuidv4();
}
