import { useMemo, useState } from 'react';
import { Card, CardHeader, IconButton } from '@mui/material';
import { EscalationPath, Site } from '../Models';
import EditEscalationPathModal from './EditEscalationPathModal';
import DeleteEscalationPathModal from './DeleteEscalationPathModal';

export default function EscalationPathCard(props: {
  path: EscalationPath;
  site: Site;
  onSave: (site: Site) => void;
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const secondaryName = useMemo(() => {
    const { steps } = props.path;
    if (steps.length === 0) {
      return 'No steps';
    }

    return steps
      .map(step => {
        const personnel = props.site.personnel.find(p => p.id === step.personnelId);
        return personnel ? personnel.name : 'Unknown';
      })
      .join(' -> ');
  }, [props.path, props.site]);

  return (
    <>
      <Card sx={{ marginBottom: '16px', paddingRight: '8px' }}>
        <CardHeader
          avatar={<span className="material-icons">schedule_send</span>}
          action={
            <>
              <IconButton aria-label="edit" onClick={() => setShowEditModal(true)}>
                <span className="material-icons">edit</span>
              </IconButton>
              <IconButton aria-label="delete" onClick={() => setShowDeleteModal(true)}>
                <span className="material-icons">delete</span>
              </IconButton>
            </>
          }
          title={props.path.name}
          titleTypographyProps={{ variant: 'subtitle1' }}
          subheader={secondaryName}
          subheaderTypographyProps={{ variant: 'subtitle2' }}
        />
      </Card>
      {showEditModal && (
        <EditEscalationPathModal
          path={props.path}
          site={props.site}
          onClose={() => setShowEditModal(false)}
          onSave={updatedSite => {
            props.onSave(updatedSite);
            setShowEditModal(false);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteEscalationPathModal
          path={props.path}
          site={props.site}
          onClose={() => setShowDeleteModal(false)}
          onSave={updatedSite => {
            props.onSave(updatedSite);
            setShowDeleteModal(false);
          }}
        />
      )}
    </>
  );
}
