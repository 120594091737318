import { request, gql } from 'graphql-request';

const SSC_GRAPHQL_ENDPOINT = 'https://api.simplysnapcloud.com/graphql';

export interface SscSite {
  id: string;
  name: string;
}

export enum SscIndustrialSensorDefinedDataType {
  CONCENTRATION = 'CONCENTRATION',
  CURRENT = 'CURRENT',
  DIFFERENTIAL_PRESSURE = 'DIFFERENTIAL_PRESSURE',
  FLOW = 'FLOW',
  HUMIDITY = 'HUMIDITY',
  MASS = 'MASS',
  PERCENTAGE = 'PERCENTAGE',
  POWER = 'POWER',
  PRESSURE = 'PRESSURE',
  TEMPERATURE = 'TEMPERATURE',
  VOLTAGE = 'VOLTAGE',
}

export type SscIndustrialSensorType =
  | { name: string }
  | { type: SscIndustrialSensorDefinedDataType };

export interface SscIndustrialSensor {
  id: string;
  name: string;
  type: SscIndustrialSensorType;
}

export enum SscDefinedAssetType {
  BAGHOUSE = 'BAGHOUSE',
  BEARING = 'BEARING',
  CHILLER = 'CHILLER',
  CNC = 'CNC',
  COMPRESSOR = 'COMPRESSOR',
  CONDENSER = 'CONDENSER',
  COOLING_TOWER = 'COOLING_TOWER',
  CUPOLA = 'CUPOLA',
  EXHAUST_FAN = 'EXHAUST_FAN',
  EXTRUDER = 'EXTRUDER',
  FURNACE = 'FURNACE',
  MAINS = 'MAINS',
  MAU = 'MAU',
  MIXER = 'MIXER',
  MOTOR = 'MOTOR',
  MULLER = 'MULLER',
  PAINT_BOOTH = 'PAINT_BOOTH',
  PANEL = 'PANEL',
  PRESS = 'PRESS',
  RTU = 'RTU',
  SCRUBBER = 'SCRUBBER',
  SHOT_BLAST = 'SHOT_BLAST',
  STAMPING_MACHINE = 'STAMPING_MACHINE',
  TRANSFORMER = 'TRANSFORMER',
  WELDER = 'WELDER',
}

export type SscAssetType = { name: string } | { type: SscDefinedAssetType };

export interface SscAsset {
  id: string;
  name: string;
  type: SscAssetType;
  descendants: { id: string }[];
  ancestors: { id: string }[];
  industrialSensors: SscIndustrialSensor[];
}

export function getMySites(jwt: string): Promise<SscSite[]> {
  const query = gql`
    query getSites {
      mySites {
        id
        name
      }
    }
  `;
  const variables = {};

  return request<{
    mySites: SscSite[];
  }>(SSC_GRAPHQL_ENDPOINT, query, variables, {
    Authorization: `Bearer ${jwt}`,
  }).then(response => response.mySites);
}

export function getAssetsForSite(jwt: string, siteId: string): Promise<SscAsset[]> {
  const query = gql`
    query getAssetsForSite($siteId: ID!) {
      site(id: $siteId) {
        assets {
          id
          name
          type {
            ... on DefinedAssetType {
              type
            }
            ... on CustomAssetType {
              name
            }
          }
          descendants {
            id
          }
          ancestors {
            id
          }
          industrialSensors {
            id
            name
            dataType {
              ... on IndustrialSensorDefinedDataType {
                type
              }
              ... on IndustrialSensorCustomDataType {
                name
              }
            }
          }
        }
      }
    }
  `;
  const variables = {
    siteId,
  };

  return request<{
    site: { assets: SscAsset[] };
  }>(SSC_GRAPHQL_ENDPOINT, query, variables, {
    Authorization: `Bearer ${jwt}`,
  }).then(response => response.site.assets);
}
