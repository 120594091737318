import * as FpoModels from '../Models';
import { SscAsset } from '../services/ssc.service';

export function toFpoAssetsFromSscAssets(sscAssets: SscAsset[]): FpoModels.Asset[] {
  type IntermediaryFpoAsset = Pick<
    FpoModels.Asset,
    'id' | 'name' | 'type' | 'monitoring' | 'meta'
  > & {
    assets: string[];
  };

  type SscAssetGraph = {
    rootIds: string[];
    assets: {
      [id: string]: IntermediaryFpoAsset;
    };
  };

  const graph: SscAssetGraph = sscAssets.reduce(
    (graph, { id, name, descendants, ancestors, industrialSensors, type }) => {
      /**
       * If the asset has no ancestors, it must be a root asset.
       */
      if (ancestors.length === 0) {
        graph.rootIds.push(id);
      }

      graph.assets[id] = {
        id,
        name,
        type: FpoModels.ASSET_TYPE_COMMISSIONED,
        assets: descendants.map(asset => asset.id),
        meta: {
          type,
        },
        monitoring: industrialSensors.map(sensor => ({
          id: sensor.id,
          meta: {
            name: sensor.name,
          },
          specs: {},
          surveyed: true,
          type: FpoModels.ENDPOINT_TYPE_COMMISSIONED,
          sscId: sensor.id,
        })),
      };

      return graph;
    },
    { rootIds: [], assets: {} } as SscAssetGraph
  );

  const resolveAsset = (id: string): FpoModels.Asset => {
    const intermediate = graph.assets[id];

    return {
      id: intermediate.id,
      name: intermediate.name,
      type: intermediate.type,
      assets: intermediate.assets.map(asset => resolveAsset(asset)),
      monitoring: intermediate.monitoring,
      meta: intermediate.meta,
      sscId: intermediate.id,
      surveyed: true,
    };
  };

  return graph.rootIds.map(id => resolveAsset(id));
}
