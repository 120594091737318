import { useState } from 'react';
import { Asset, EndPoint, EndPointSpec, FlattenedAsset, JSONObject } from '../Models';
import { actionForAssetType } from '../Actions';
import { Button, Card, CardHeader } from '@mui/material';
import { Questionnaire } from '../QuestionnaireComponent';

export default function FlatAssetCard(props: {
  asset: FlattenedAsset;
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void;
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void;
}) {
  const [showSpecModal, setShowSpecModal] = useState(false);

  return (
    <>
      <Card
        sx={{
          marginBottom: '16px',
          padding: '8px 16px 8px 8px',
          height: '72px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CardHeader
          title={props.asset.custodyName}
          titleTypographyProps={{ variant: 'caption' }}
          subheader={props.asset.name}
          subheaderTypographyProps={{ variant: 'subtitle1' }}
        />
        <Button variant="outlined" color="primary" onClick={() => setShowSpecModal(true)}>
          Spec Asset
        </Button>
      </Card>
      {showSpecModal && (
        <Questionnaire
          projectNode={props.asset}
          chainOfCustody={props.asset.chainOfCustody}
          valuesCallback={values => {
            actionForAssetType(
              props.asset,
              props.asset.chainOfCustody,
              props.addNewAssetsAndEndPoints,
              props.specEndPoint
            )(values);

            console.log('speccing', props, values);

            setShowSpecModal(false);
          }}
          onClose={() => setShowSpecModal(false)}
        />
      )}
    </>
  );
}
