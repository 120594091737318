import { useState } from 'react';
import { Card, CardHeader, IconButton } from '@mui/material';
import { Personnel, Site } from '../Models';
import EditPersonnelModal from './EditPersonnelModal';
import DeletePersonnelModal from './DeletePersonnelModal';

export default function PersonnelCard(props: {
  person: Personnel;
  site: Site;
  onSave: (site: Site) => void;
  hideControls?: boolean;
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <Card sx={{ marginBottom: '16px', paddingRight: '8px' }}>
        <CardHeader
          avatar={<span className="material-icons">person</span>}
          action={
            !props.hideControls && (
              <>
                <IconButton aria-label="edit" onClick={() => setShowEditModal(true)}>
                  <span className="material-icons">edit</span>
                </IconButton>
                <IconButton aria-label="delete" onClick={() => setShowDeleteModal(true)}>
                  <span className="material-icons">delete</span>
                </IconButton>
              </>
            )
          }
          title={props.person.name}
          titleTypographyProps={{ variant: 'subtitle1' }}
          subheader={props.person.email}
          subheaderTypographyProps={{ variant: 'subtitle2' }}
        />
      </Card>
      {showEditModal && (
        <EditPersonnelModal
          person={props.person}
          site={props.site}
          onClose={() => setShowEditModal(false)}
          onSave={updatedSite => {
            props.onSave(updatedSite);
            setShowEditModal(false);
          }}
        />
      )}
      {showDeleteModal && (
        <DeletePersonnelModal
          person={props.person}
          site={props.site}
          onClose={() => setShowDeleteModal(false)}
          onSave={updatedSite => {
            props.onSave(updatedSite);
            setShowDeleteModal(false);
          }}
        />
      )}
    </>
  );
}
