import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FieldArray, Form, Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { useAxios } from '../libs/axios';
import { PUT_SITE_URL } from '../const';
import FormLayout from '../FormLayout';
import { EscalationPath, EscalationStep, MAX_ESCALATION_STEPS, Site } from '../Models';

export default function EditEscalationPathModal({
  site,
  path,
  onClose,
  onSave,
}: {
  onClose: () => void;
  onSave: (site: Site) => void;
  site: Site;
  path: EscalationPath;
}) {
  const { axios } = useAxios();

  return (
    <Formik
      initialValues={path}
      validationSchema={Yup.object({
        name: Yup.string().min(1).required('Required'),
        steps: Yup.array().of(
          Yup.object({
            personnelId: Yup.string().required('Required'),
            delayInMinutes: Yup.number().required('Required').min(0),
          })
        ),
      })}
      onSubmit={async values => {
        const updatedEscalationPath: EscalationPath = {
          id: path.id,
          name: values.name,
          steps: values.steps,
        };

        const updatedSite: Site = {
          ...site,
          escalationPaths: site.escalationPaths.map(p => {
            if (p.id === updatedEscalationPath.id) {
              return updatedEscalationPath;
            }
            return p;
          }),
        };

        await axios.put(PUT_SITE_URL, updatedSite).then(response => {
          onSave(response.data);
        });
      }}
    >
      {formik => (
        <Dialog open={true} onClose={onClose}>
          <Form>
            <DialogTitle>Edit {path.name}</DialogTitle>
            <DialogContent>
              <FormLayout>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <FieldArray
                  name="steps"
                  render={arrayHelpers => (
                    <>
                      {formik.values.steps.map((step, index) => {
                        const personnelIdName = `steps[${index}].personnelId`;
                        const delayInMinutesName = `steps[${index}].delayInMinutes`;

                        return (
                          <Card key={index}>
                            <CardHeader
                              title={`Step ${index + 1}`}
                              titleTypographyProps={{ variant: 'subtitle1' }}
                              action={
                                formik.values.steps.length > 1 && (
                                  <IconButton onClick={() => arrayHelpers.remove(index)}>
                                    <span className="material-icons">close</span>
                                  </IconButton>
                                )
                              }
                            />
                            <CardContent
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '220px 120px',
                                columnGap: '16px',
                              }}
                            >
                              <FormControl fullWidth>
                                <InputLabel>Person</InputLabel>
                                <Select
                                  label="Person"
                                  name={personnelIdName}
                                  value={formik.values.steps[index].personnelId}
                                  onChange={formik.handleChange}
                                  error={
                                    getIn(formik.touched, personnelIdName) &&
                                    Boolean(getIn(formik.errors, personnelIdName))
                                  }
                                  variant="filled"
                                >
                                  {site.personnel.map(person => {
                                    return (
                                      <MenuItem key={person.id} value={person.id}>
                                        {person.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <FormHelperText>
                                  {getIn(formik.touched, personnelIdName) &&
                                    getIn(formik.errors, personnelIdName)}
                                </FormHelperText>
                              </FormControl>
                              <TextField
                                margin="dense"
                                name={delayInMinutesName}
                                label="Delay in Minutes"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formik.values.steps[index].delayInMinutes}
                                onChange={formik.handleChange}
                                error={
                                  getIn(formik.touched, delayInMinutesName) &&
                                  Boolean(getIn(formik.errors, delayInMinutesName))
                                }
                                helperText={
                                  getIn(formik.touched, delayInMinutesName) &&
                                  getIn(formik.errors, delayInMinutesName)
                                }
                              />
                            </CardContent>
                          </Card>
                        );
                      })}
                      {formik.values.steps.length < MAX_ESCALATION_STEPS && (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              arrayHelpers.push({
                                personnelId: '',
                                delayInMinutes: 0,
                              } as EscalationStep)
                            }
                          >
                            <span className="material-icons">add</span>
                          </IconButton>
                        </div>
                      )}
                    </>
                  )}
                />
              </FormLayout>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button color="secondary" type="submit" disabled={formik.isSubmitting}>
                Save Escalation Path
              </Button>
              <Button onClick={onClose} color="warning">
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
