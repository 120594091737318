import React, { cloneElement } from 'react';

import { Field, ErrorMessage, FormikProps } from 'formik';
import { Input } from '@mui/material';

function QuestionLayout(props: {
  fieldName: string;
  label: React.ReactElement;
  tooltip: string;
  children: React.ReactElement;
}) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateAreas: `
          'label tooltip'
          'input input'
          'error error'
        `,
        gridTemplateColumns: '1fr 32px',
        marginBottom: '24px',
        rowGap: '4px',
        columnGap: '16px',
      }}
    >
      {cloneElement(props.label, { style: { gridArea: 'label' } })}
      <div style={{ gridArea: 'tooltip' }} data-tip={props.tooltip} data-place="top">
        <span className="material-icons">live_help</span>
      </div>
      {cloneElement(props.children, { style: { gridArea: 'input' } })}
      <div style={{ gridArea: 'error' }}>
        <ErrorMessage name={props.fieldName} />
      </div>
    </div>
  );
}

export function EmailQuestion(props: {
  fieldName: string;
  text: string;
  default: number | string;
  tooltip: string;
  options: string[];
  formik: FormikProps<any>;
}) {
  return (
    <QuestionLayout
      fieldName={props.fieldName}
      tooltip={props.tooltip}
      label={<label htmlFor={props.fieldName}>{props.text}</label>}
    >
      <Input name={props.fieldName} type="text" />
    </QuestionLayout>
  );
}

export function SnapAddressQuestion(props: {
  fieldName: string;
  text: string;
  default: number | string;
  tooltip: string;
  options: string[];
  formik: FormikProps<any>;
}) {
  return (
    <QuestionLayout
      fieldName={props.fieldName}
      tooltip={props.tooltip}
      label={<label htmlFor={props.fieldName}>{props.text}</label>}
    >
      <Input name={props.fieldName} type="text" />
    </QuestionLayout>
  );
}

export function NotesQuestion(props: {
  fieldName: string;
  text: string;
  default: number | string;
  tooltip: string;
  options: string[];
  formik: FormikProps<any>;
}) {
  return (
    <QuestionLayout
      fieldName={props.fieldName}
      tooltip={props.tooltip}
      label={<label htmlFor={props.fieldName}>{props.text}</label>}
    >
      <Field name={props.fieldName} type="text" />
    </QuestionLayout>
  );
}

export function PercentQuestion(props: {
  fieldName: string;
  text: string;
  default: number | string;
  tooltip: string;
  options: string[];
  formik: FormikProps<any>;
}) {
  return (
    <QuestionLayout
      fieldName={props.fieldName}
      tooltip={props.tooltip}
      label={<label htmlFor={props.fieldName}>{props.text}</label>}
    >
      <Field name={props.fieldName} type="text" />
    </QuestionLayout>
  );
}

export function NumberQuestion(props: {
  fieldName: string;
  text: string;
  default: number | string;
  tooltip: string;
  options: string[];
  formik: FormikProps<any>;
}) {
  return (
    <QuestionLayout
      fieldName={props.fieldName}
      tooltip={props.tooltip}
      label={<label htmlFor={props.fieldName}>{props.text}</label>}
    >
      <Field name={props.fieldName} type="text" />
    </QuestionLayout>
  );
}

export function YesNoQuestion(props: {
  fieldName: string;
  text: string;
  default: string | number;
  tooltip: string;
  options: string[];
  formik: FormikProps<any>;
}) {
  const groupId = `${props.fieldName.replace('_', '-')}-group`;
  return (
    <QuestionLayout
      fieldName={props.fieldName}
      tooltip={props.tooltip}
      label={<div id={groupId}>{props.text}</div>}
    >
      <div role="group" aria-labelledby={groupId}>
        <label>
          <Field type="radio" name={props.fieldName} value="Yes" />
          Yes
        </label>
        <label>
          <Field type="radio" name={props.fieldName} value="No" />
          No
        </label>
      </div>
    </QuestionLayout>
  );
}

export function DropDownQuestion(props: {
  fieldName: string;
  text: string;
  default: string | number;
  tooltip: string;
  options: string[];
  formik: FormikProps<any>;
}) {
  return (
    <QuestionLayout
      fieldName={props.fieldName}
      tooltip={props.tooltip}
      label={<label htmlFor={props.fieldName}>{props.text}</label>}
    >
      <Field name={props.fieldName} as="select">
        {props.options.map(option => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </Field>
    </QuestionLayout>
  );
}
