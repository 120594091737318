import { Fab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { GET_SITE_URL, PROJECTS_INDEX_URL } from '../const';
import { useAxios } from '../libs/axios';
import LoadingIndicator from '../LoadingIndicator';
import { Project, Site } from '../Models';
import Page from '../Page';
import AddPersonnelModal from '../personnel/AddPersonnelModal';
import PersonnelCard from '../personnel/PersonnelCard';
import ProjectCard from '../projects/ProjectCard';
import AddEscalationPathModal from '../escalation-paths/AddEscalationPathModal';
import EscalationPathCard from '../escalation-paths/EscalationPathCard';

export default function SitePage() {
  const { axios } = useAxios();
  const [site, setSite] = useState<Site>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [showAddPersonModal, setShowAddPersonModal] = useState(false);
  const [showAddEscalationPathModal, setShowAddEscalationPathModal] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (!params.siteId) return;

    axios.get(GET_SITE_URL(params.siteId)).then(response => {
      setSite(response.data);
    });
  }, [axios, params.siteId]);

  useEffect(() => {
    if (!params.siteId) return;

    axios.get(PROJECTS_INDEX_URL).then(response => {
      const allProjects: Project[] = response.data;

      setProjects(allProjects.filter(project => project.siteId === params.siteId));
      setIsLoadingProjects(false);
    });
  }, [axios, params.siteId]);

  if (!site || isLoadingProjects) {
    return <LoadingIndicator />;
  }

  return (
    <Page>
      <Typography variant="h4" gutterBottom>
        {site.name}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 0 16px',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Personnel
        </Typography>
        <div>
          <Fab
            variant="extended"
            color="secondary"
            size="medium"
            onClick={() => setShowAddPersonModal(true)}
          >
            <Add sx={{ mr: 1 }} />
            Add New Person
          </Fab>
        </div>
      </div>
      {site.personnel.map(person => (
        <PersonnelCard
          key={person.id}
          person={person}
          site={site}
          onSave={updatedSite => setSite(updatedSite)}
        />
      ))}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 0 16px',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Reports
        </Typography>
        <div>
          <Fab
            variant="extended"
            size="medium"
            onClick={() => {
              throw new Error('Not implemented');
            }}
          >
            <Add sx={{ mr: 1 }} />
            Deploy Reports
          </Fab>
        </div>
      </div>
      {site.personnel
        .filter(person => person.receivesReports)
        .map(person => (
          <PersonnelCard
            key={person.id}
            person={person}
            site={site}
            onSave={updatedSite => setSite(updatedSite)}
            hideControls={true}
          />
        ))}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 0 16px',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Escalation Paths
        </Typography>
        <div>
          <Fab
            variant="extended"
            size="medium"
            onClick={() => console.log('Not Implemented')}
            style={{ marginRight: '8px' }}
          >
            Deploy Paths
          </Fab>
          <Fab
            variant="extended"
            color="secondary"
            size="medium"
            onClick={() => setShowAddEscalationPathModal(true)}
          >
            <Add sx={{ mr: 1 }} />
            Add Escalation Path
          </Fab>
        </div>
      </div>
      {(site.escalationPaths ?? []).map(path => (
        <EscalationPathCard
          key={path.id}
          path={path}
          site={site}
          onSave={updatedSite => setSite(updatedSite)}
        />
      ))}
      <Typography variant="h5" gutterBottom>
        Projects
      </Typography>
      {projects.map(project => (
        <ProjectCard key={project.id} project={project} />
      ))}
      {showAddPersonModal && (
        <AddPersonnelModal
          onClose={() => setShowAddPersonModal(false)}
          onSave={updatedSite => {
            setSite(updatedSite);
            setShowAddPersonModal(false);
          }}
          site={site}
        />
      )}
      {showAddEscalationPathModal && (
        <AddEscalationPathModal
          onClose={() => setShowAddEscalationPathModal(false)}
          onSave={updatedSite => {
            setSite(updatedSite);
            setShowAddEscalationPathModal(false);
          }}
          site={site}
        />
      )}
    </Page>
  );
}
