import { Formik, Form } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { EndPoint, EndpointTypeUnion, ENDPOINT_TYPES } from './Models';
import {
  Modal,
  Box,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from '@mui/material';
import FormLayout from './FormLayout';

export default function NewEndPointComponent(props: {
  chainOfCustody: string[];
  addEndPoint: (endpoint: EndPoint, chainOfCustody: string[]) => void;
  onCancel: () => void;
}) {
  return (
    <Modal open={true} onClose={props.onCancel}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Formik
          initialValues={{ type: 'POWER' as EndpointTypeUnion }}
          validationSchema={Yup.object({
            type: Yup.string().min(1).required('Required'),
          })}
          onSubmit={values => {
            props.addEndPoint(
              {
                ...values,
                id: uuidv4(),
                specs: {},
                surveyed: false,
                meta: { ...values },
              },
              props.chainOfCustody
            );
          }}
        >
          {formik => (
            <Form>
              <FormLayout>
                <Typography variant="h6">Add Monitoring Endpoint</Typography>

                <FormControl fullWidth>
                  <InputLabel>Monitoring Type</InputLabel>
                  <Select
                    label="Monitoring Type"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    variant="filled"
                  >
                    {ENDPOINT_TYPES.map(type => {
                      return (
                        <MenuItem key={type['id']} value={type['id']}>
                          {type['display']}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{formik.touched.type && formik.errors.type}</FormHelperText>
                </FormControl>

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  Save Monitoring Endpoint
                </Button>
              </FormLayout>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
