import { Card, CardHeader, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Site } from '../Models';

export default function SiteCard(props: { site: Site }) {
  const navigate = useNavigate();

  return (
    <Card sx={{ marginBottom: '16px', paddingRight: '8px' }}>
      <CardHeader
        avatar={<span className="material-icons">factory</span>}
        action={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/site/${props.site.id}`)}
          >
            Visit Site
          </Button>
        }
        title={props.site.name}
        titleTypographyProps={{ variant: 'subtitle1' }}
      />
    </Card>
  );
}
