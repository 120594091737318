import { v4 as uuidv4 } from 'uuid';

import { Asset, EndPoint, EndPointSpec } from './Models';

import * as Model from './Models';

import { POWER_UNIT_AMPS } from './const';

const powerToAmps = (voltage: number, power: number, unit: string) => {
  if (unit === POWER_UNIT_AMPS) {
    return power;
  }

  throw new Error(`Unknown unit "${unit}" provided to amp conversion function.`);
};

export function actionForEndPointType(
  endpoint: EndPoint,
  chainOfCustody: string[],
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  switch (endpoint.type) {
    case Model.ENDPOINT_TYPE_POWER: {
      return (values: { [key: string]: string | number }) => {
        specEndPoint(
          // No type checking really happens here either.
          {
            ...values,
            id: uuidv4(),
            amps: powerToAmps(
              parseInt(`${values.voltage}`, 10),
              parseInt(`${values.power}`, 10),
              `${values.unit}`
            ), // UG: Do you really want to do this conversion here?
          } as Model.PowerEndPointSpec,
          chainOfCustody,
          endpoint.id
        );
      };
    }
    case Model.ENDPOINT_TYPE_MACHINE_HEALTH: {
      return (values: { [key: string]: string | number }) => {
        specEndPoint(
          {
            ...values,
            id: uuidv4(),
          },
          chainOfCustody,
          endpoint.id
        );
      };
    }
    case Model.ENDPOINT_TYPE_SILO_LEVEL: {
      return (values: any) => {
        specEndPoint(
          {
            ...values,
            id: uuidv4(),
          },
          chainOfCustody,
          endpoint.id
        );
      };
    }
    default:
      return (values: any) => {
        console.log(values);
      };
  }
}

const repeat = (count: number) => (f: any) => {
  if (count > 0) {
    f();
    repeat(count - 1)(f);
  }
};

function yes(answer: string) {
  if (answer.toLowerCase() === 'yes') {
    return true;
  } else if (answer.toLowerCase() === 'no') {
    return false;
  } else {
    throw Error(`Answer ${answer} is neither "Yes" or "No".`);
  }
}

const newEndPoint = (type: Model.EndpointTypeUnion) => {
  const id = uuidv4();

  return {
    id,
    type: type,
    specs: {},
    surveyed: false,
    meta: {},
  };
};

const newAsset = (
  name: string,
  type: Model.AssetTypeUnion,
  assets: Asset[],
  monitoring: EndPoint[],
  meta: Model.JSONObject
) => {
  const id = uuidv4();

  return {
    id,
    name: `${name} ${id.slice(0, 4)}`,
    type: type,
    assets: assets,
    monitoring: monitoring,
    surveyed: false,
    meta: meta,
  };
};

function baghouseAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newAssets: Asset[] = [];
    const newEndPoints: EndPoint[] = [];

    repeat(values.numOfDrives)(() => {
      newAssets.push(
        newAsset(
          'Powertrain',
          Model.ASSET_TYPE_POWERTRAIN,
          [newAsset('Motor', Model.ASSET_TYPE_MOTOR, [], [], {})],
          [],
          { ...values }
        )
      );
    });

    repeat(values.numOfConveyors)(() => {
      newAssets.push(newAsset('Conveyor', Model.ASSET_TYPE_CONVEYOR, [], [], {}));
    });

    if (yes(values.hasFilterSenseBPAC)) {
      newAssets.push(newAsset('FilterSense BPAC', Model.ASSET_TYPE_FILTERSENSE_BPAC, [], [], {}));
    }

    repeat(values.numOfDiffPressure)(() => {
      newEndPoints.push(newEndPoint(Model.ENDPOINT_TYPE_DIFFERENTIAL_PRESSURE));
    });

    repeat(values.numOfParticleDetectors)(() => {
      newEndPoints.push(newEndPoint(Model.ENDPOINT_TYPE_PARTICLE_SENSING));
    });

    addNewAssetsAndEndPoints(newAssets, newEndPoints, values, chainOfCustody, true);
  };
}

function compressorAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newAssets: Asset[] = [];
    const newEndPoints: EndPoint[] = [];

    const newOutputPipeEndpoints: EndPoint[] = [];

    if (yes(values.doPowerMonitoring)) {
      newEndPoints.push(newEndPoint(Model.ENDPOINT_TYPE_POWER));
    }

    repeat(values.numOfHealthEndPoints)(() => {
      newEndPoints.push(newEndPoint(Model.ENDPOINT_TYPE_MACHINE_HEALTH));
    });

    if (yes(values.doFlowMonitoring)) {
      newOutputPipeEndpoints.push(newEndPoint(Model.ENDPOINT_TYPE_COMPRESSED_AIR_FLOW));
    }

    if (yes(values.doPressureMonitoring)) {
      newOutputPipeEndpoints.push(newEndPoint(Model.ENDPOINT_TYPE_COMPRESSED_AIR_PRESSURE));
    }

    if (yes(values.doTemperatureMonitoring)) {
      newOutputPipeEndpoints.push(newEndPoint(Model.ENDPOINT_TYPE_COMPRESSED_AIR_TEMP));
    }

    if (yes(values.isSchedulePipe)) {
      newAssets.push(
        newAsset(
          'Compressor Output Line',
          Model.ASSET_TYPE_SCHEDULE_COMPRESSED_AIR_PIPE,
          [],
          newOutputPipeEndpoints,
          {}
        )
      );
    } else {
      newAssets.push(
        newAsset(
          'Compressor Output Line',
          Model.ASSET_TYPE_NON_SCHEDULE_COMPRESSED_AIR_PIPE,
          [],
          newOutputPipeEndpoints,
          {}
        )
      );
    }

    addNewAssetsAndEndPoints(newAssets, newEndPoints, values, chainOfCustody, true);
  };
}

function rtuAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newAssets: Asset[] = [];
    const newEndPoints: EndPoint[] = [];

    if (yes(values.doPowerMonitoring)) {
      newEndPoints.push(newEndPoint(Model.ENDPOINT_TYPE_POWER));
    }

    if (yes(values.doVibrationAndTempMonitoring)) {
      newAssets.push(
        newAsset(
          'Compressor Motor',
          Model.ASSET_TYPE_MOTOR,
          [],
          [newEndPoint(Model.ENDPOINT_TYPE_MACHINE_HEALTH)],
          { ...values }
        )
      );
      newAssets.push(
        newAsset(
          'Fan Motor',
          Model.ASSET_TYPE_MOTOR,
          [],
          [newEndPoint(Model.ENDPOINT_TYPE_MACHINE_HEALTH)],
          { ...values }
        )
      );
    }

    if (yes(values.doClimateControls)) {
      newEndPoints.push(newEndPoint(Model.ENDPOINT_TYPE_CLIMATE_CONTROL));
    }

    newAssets.map(asset => {
      return (asset.surveyed = true);
    });

    addNewAssetsAndEndPoints(newAssets, newEndPoints, values, chainOfCustody, true);
  };
}

function siloAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newEndPoints: EndPoint[] = [];

    if (yes(values.doLevelMonitoring)) {
      newEndPoints.push(newEndPoint(Model.ENDPOINT_TYPE_SILO_LEVEL));
    }

    addNewAssetsAndEndPoints([], newEndPoints, values, chainOfCustody, true);
  };
}

function bearingAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newEndPoints: EndPoint[] = [];

    if (yes(values.doMachineHealth)) {
      newEndPoints.push(newEndPoint(Model.ENDPOINT_TYPE_MACHINE_HEALTH));
    }

    addNewAssetsAndEndPoints([], newEndPoints, values, chainOfCustody, true);
  };
}

function motorAction( // U.G. Consolidate these action functions. Too much duplication.
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newEndPoints: EndPoint[] = [];

    if (yes(values.doMachineHealth)) {
      newEndPoints.push(newEndPoint(Model.ENDPOINT_TYPE_MACHINE_HEALTH));
    }

    if (yes(values.doPowerMonitoring)) {
      newEndPoints.push(newEndPoint(Model.ENDPOINT_TYPE_POWER));
    }

    addNewAssetsAndEndPoints([], newEndPoints, values, chainOfCustody, true);
  };
}

function storeToMetaAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    addNewAssetsAndEndPoints([], [], values, chainOfCustody, true);
  };
}

function powertrainAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newAssets: Asset[] = [];
    const newEndPoints: EndPoint[] = [];

    repeat(values.numOfBearings)(() => {
      newAssets.push(newAsset('Bearing', Model.ASSET_TYPE_BEARING, [], [], {}));
    });

    addNewAssetsAndEndPoints(newAssets, newEndPoints, values, chainOfCustody, true);
  };
}

function transformer3PhaseAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newAssets: Asset[] = [];
    const newEndPoints: EndPoint[] = [];

    const voltage =
      values.inputOrOutputSide === 'Output Side' ? values.outputVoltage : values.inputVoltage;
    const amps = Math.round((1000 * values.kva) / (1.732 * voltage));

    repeat(3)(() => {
      const spec: Model.PowerEndPointSpec = {
        id: uuidv4(),
        voltage: voltage,
        phases: 3,
        power: amps,
        unit: POWER_UNIT_AMPS,
        amps: amps,
        extrapolation: false,
        exposed: yes(values.isExposedToElements),
        insulated: yes(values.isInsulated),
      };

      const aPhaseEndpoint = newEndPoint(Model.ENDPOINT_TYPE_POWER);
      aPhaseEndpoint.specs = spec;
      aPhaseEndpoint.surveyed = true;
      newEndPoints.push(aPhaseEndpoint);
    });

    addNewAssetsAndEndPoints(newAssets, newEndPoints, values, chainOfCustody, true);
  };
}

function transformer3PhaseGroupAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newAssets: Asset[] = [];
    const newEndPoints: EndPoint[] = [];

    const voltage =
      values.inputOrOutputSide === 'Output Side' ? values.outputVoltage : values.inputVoltage;
    const amps = Math.round((1000 * values.kva) / (1.732 * voltage));

    ['A', 'B', 'C'].forEach(phase_id => {
      const phaseEndpoint = newEndPoint(Model.ENDPOINT_TYPE_POWER);
      phaseEndpoint.specs = {
        id: uuidv4(),
        voltage: voltage,
        phases: 3,
        power: amps,
        unit: POWER_UNIT_AMPS,
        amps: amps,
        extrapolation: false,
        exposed: yes(values.isExposedToElements),
        insulated: yes(values.isInsulated),
      };
      phaseEndpoint.surveyed = true;

      const phase = newAsset(
        `Phase ${phase_id}`,
        Model.ASSET_TYPE_SINGLE_PHASE_TRANSFORMER,
        [],
        [phaseEndpoint],
        values
      );
      phase.surveyed = true;

      newAssets.push(phase);
    });

    addNewAssetsAndEndPoints(newAssets, newEndPoints, values, chainOfCustody, true);
  };
}

function scheduleCompressedAirPipeAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newAssets: Asset[] = [];
    const newEndPoints: EndPoint[] = [];

    addNewAssetsAndEndPoints(newAssets, newEndPoints, values, chainOfCustody, true);
  };
}

function nonScheduleCompressedAirPipeAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newAssets: Asset[] = [];
    const newEndPoints: EndPoint[] = [];

    addNewAssetsAndEndPoints(newAssets, newEndPoints, values, chainOfCustody, true);
  };
}

function scheduleWaterPipeAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newAssets: Asset[] = [];
    const newEndPoints: EndPoint[] = [];

    addNewAssetsAndEndPoints(newAssets, newEndPoints, values, chainOfCustody, true);
  };
}

function nonScheduleWaterPipeAction(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  return (values: any) => {
    const newAssets: Asset[] = [];
    const newEndPoints: EndPoint[] = [];

    addNewAssetsAndEndPoints(newAssets, newEndPoints, values, chainOfCustody, true);
  };
}

export function actionForAssetType(
  asset: Asset,
  chainOfCustody: string[],
  addNewAssetsAndEndPoints: (
    newAssets: Asset[],
    newEndPoints: EndPoint[],
    meta: Model.JSONObject,
    chainOfCustody: string[],
    surveyed: boolean
  ) => void,
  specEndPoint: (
    newEndPointSpec: EndPointSpec,
    chainOfCustody: string[],
    endpointId: string
  ) => void
) {
  switch (
    asset.type // U.G. De-duplicate this code.
  ) {
    case Model.ASSET_TYPE_COMPRESSOR: {
      return compressorAction(asset, chainOfCustody, addNewAssetsAndEndPoints, specEndPoint);
    }
    case Model.ASSET_TYPE_SILO: {
      return siloAction(asset, chainOfCustody, addNewAssetsAndEndPoints, specEndPoint);
    }
    case Model.ASSET_TYPE_MOTOR: {
      return motorAction(asset, chainOfCustody, addNewAssetsAndEndPoints, specEndPoint);
    }
    case Model.ASSET_TYPE_BEARING: {
      return bearingAction(asset, chainOfCustody, addNewAssetsAndEndPoints, specEndPoint);
    }
    case Model.ASSET_TYPE_POWERTRAIN: {
      return powertrainAction(asset, chainOfCustody, addNewAssetsAndEndPoints, specEndPoint);
    }
    case Model.ASSET_TYPE_BAGHOUSE: {
      return baghouseAction(asset, chainOfCustody, addNewAssetsAndEndPoints, specEndPoint);
    }
    case Model.ASSET_TYPE_3_PHASE_TRANSFORMER: {
      return transformer3PhaseAction(asset, chainOfCustody, addNewAssetsAndEndPoints, specEndPoint);
    }
    case Model.ASSET_TYPE_3_PHASE_TRANSFORMER_GROUP: {
      return transformer3PhaseGroupAction(
        asset,
        chainOfCustody,
        addNewAssetsAndEndPoints,
        specEndPoint
      );
    }
    case Model.ASSET_TYPE_METER_ELECTRICAL: {
      return storeToMetaAction(asset, chainOfCustody, addNewAssetsAndEndPoints, specEndPoint);
    }
    case Model.ASSET_TYPE_RTU: {
      return rtuAction(asset, chainOfCustody, addNewAssetsAndEndPoints, specEndPoint);
    }
    case Model.ASSET_TYPE_SCHEDULE_COMPRESSED_AIR_PIPE: {
      return scheduleCompressedAirPipeAction(
        asset,
        chainOfCustody,
        addNewAssetsAndEndPoints,
        specEndPoint
      );
    }
    case Model.ASSET_TYPE_NON_SCHEDULE_COMPRESSED_AIR_PIPE: {
      return nonScheduleCompressedAirPipeAction(
        asset,
        chainOfCustody,
        addNewAssetsAndEndPoints,
        specEndPoint
      );
    }
    case Model.ASSET_TYPE_SCHEDULE_WATER_PIPE: {
      return scheduleWaterPipeAction(asset, chainOfCustody, addNewAssetsAndEndPoints, specEndPoint);
    }
    case Model.ASSET_TYPE_NON_SCHEDULE_WATER_PIPE: {
      return nonScheduleWaterPipeAction(
        asset,
        chainOfCustody,
        addNewAssetsAndEndPoints,
        specEndPoint
      );
    }
    default:
      return (values: any) => {
        console.log(values);
      };
  }
}
