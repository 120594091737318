import {
  YesNoQuestion,
  DropDownQuestion,
  NotesQuestion,
  NumberQuestion,
  PercentQuestion,
  EmailQuestion,
} from './QuestionComponents';

import * as model from './Models';
import { POWER_UNIT_AMPS } from './const';

export interface QuestionDefinition {
  fieldName: string;
  text: string;
  default: string | number;
  format: Function;
  tooltip: string;
  options: string[];
}

function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}

export function questionsForType(type: model.EndpointTypeUnion | model.AssetTypeUnion) {
  switch (type) {
    case model.ASSET_TYPE_MOTOR:
      return [
        {
          fieldName: 'doPowerMonitoring',
          text: 'Would you like to monitor the power consumption?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Monitoring energy usage on motors gives you detailed usage/cost information, equipment state (on/off) data, and provides additional data points for equipment health monitoring.',
        },
        {
          fieldName: 'doMachineHealth',
          text: 'Would you like to monitor Machine State and Health for this motor?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Temperature and vibration information from machine state and health sensors can warn you of failing equipment.',
        },
      ];
    case model.ASSET_TYPE_BAGHOUSE:
      return [
        {
          fieldName: 'numOfDrives',
          text: 'How many motors / powertrains power this baghouse?',
          default: 1,
          format: NumberQuestion,
          options: [],
          tooltip:
            'Identifying the individual elements powering the baghouse can help in the location of problems.',
        },
        {
          fieldName: 'numOfConveyors',
          text: 'How many conveyors on this baghouse do you want to monitor?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip: 'Monitoring conveyors inside the baghouse can identify problems.',
        },
        {
          fieldName: 'hasFilterSenseBPAC',
          text: 'Does this baghouse have an installed FilterSense BPAC system?',
          default: 'No',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'SimplySnap can integrate with FilterSense BPAC systems provide a single interface and forward problems via PagerDuty.',
        },
        {
          fieldName: 'numOfDiffPressure',
          text: 'How many stand-alone differential pressure sensors do you want?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'Monitoring the differential pressure in baghouses can save money on filter changes.',
        },
        {
          fieldName: 'numOfParticleDetectors',
          text: 'How many stand-alone particle detectors do you want?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'Monitoring for particles can tell you if your bags or filters are full or leaking.',
        },
      ];
    case model.ASSET_TYPE_BEARING:
      return [
        {
          fieldName: 'doMachineHealth',
          text: 'Would you like to monitor Machine State and Health for this bearing?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Temperature and vibration information from machine state and health sensors can warn you of failing equipment.',
        },
      ];
    case model.ASSET_TYPE_3_PHASE_TRANSFORMER:
      return [
        {
          fieldName: 'kva',
          text: "What is the transformer's total kVA?",
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'The totalized kVA rating for the transformer, not the kVA of the individual phases.',
        },
        {
          fieldName: 'inputVoltage',
          text: 'What voltage is being INPUT INTO the transformer?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'Transformers take an input voltage and step that voltage higher or lower.  What is the voltage being INPUT into this transformer?',
        },
        {
          fieldName: 'outputVoltage',
          text: 'What voltage is being OUTPUT FROM this transformer?',
          default: 480,
          format: NumberQuestion,
          options: [],
          tooltip:
            'Transformers take an input voltage and step that voltage higher or lower.  What is the voltage being OUTPUT from this transformer?',
        },
        {
          fieldName: 'inputOrOutputSide',
          text: 'Will we be monitoring the input or the output side of this transformer?',
          default: 'Output Side',
          format: DropDownQuestion,
          options: ['Input Side', 'Output Side'],
          tooltip: "It's generally safer to monitor lower voltages and/or insulated cables.",
        },
        {
          fieldName: 'isExposedToElements',
          text: 'Will the monitoring location be exposed to the elements?',
          default: 'No',
          format: YesNoQuestion,
          options: [],
          tooltip: "If the sensor will be exposed to the weather, hardier devices must be spec'd.",
        },
        {
          fieldName: 'isInsulated',
          text: 'Is the cable at the monitoring location insulated?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip: 'Uninsulated cables require special consideration when speccing devices.',
        },
      ];
    case model.ASSET_TYPE_3_PHASE_TRANSFORMER_GROUP:
      return [
        {
          fieldName: 'kva',
          text: 'What is the individual kVA rating of each of the single phase transformers in the group?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'The individual kVA rating of the transformers in this group.  Not the summed total of the group.',
        },
        {
          fieldName: 'inputVoltage',
          text: 'What voltage is being INPUT INTO the transformer group?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'Transformers take an input voltage and step that voltage higher or lower.  What is the line-to-line voltage being INPUT into this transformer?',
        },
        {
          fieldName: 'outputVoltage',
          text: 'What voltage is being OUTPUT BY this transformer group?',
          default: 480,
          format: NumberQuestion,
          options: [],
          tooltip:
            'Transformers take an input voltage and step that voltage higher or lower.  What is the line-to-line voltage being OUTPUT from this transformer?',
        },
        {
          fieldName: 'inputOrOutputSide',
          text: 'Will we be monitoring the input or the output side of this transformer?',
          default: 'Output Side',
          format: DropDownQuestion,
          options: ['Input Side', 'Output Side'],
          tooltip:
            "It's generally safer to monitor lower voltages and/or insulated cables.  Choose the side that meets these critera.",
        },
        {
          fieldName: 'isExposedToElements',
          text: 'Will the monitoring location be exposed to the elements?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip: "If the sensor will be exposed to the weather, hardier devices must be spec'd.",
        },
        {
          fieldName: 'isInsulated',
          text: 'Is the cable at the monitoring location insulated?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip: 'Uninsulated cables require special consideration when speccing devices.',
        },
      ];
    case model.ASSET_TYPE_METER_ELECTRICAL:
      return [
        {
          fieldName: 'siteElectricalExpert',
          text: "E-mail of point of contact with most knowledge of the site's electrical layout.",
          default: '',
          format: EmailQuestion,
          options: [],
          tooltip:
            "We'll need to ask questions about how power is spread from this meter throughout the site.",
        },
        {
          fieldName: 'siteElectricalContractor',
          text: 'E-mail of point of contact for getting electrical work done at the site.',
          default: '',
          format: EmailQuestion,
          options: [],
          tooltip:
            "We'll need to this informatino to help coordinate with the on-staff or contract electrician for the site.",
        },
      ];
    case model.ASSET_TYPE_POWERTRAIN:
      return [
        {
          fieldName: 'numOfBearings',
          text: 'How many bearings in this powertrain would like to monitor for machine state and health?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            "A bearing failing in a powertrain can bring a huge asset to a halt.  Machine State and Health monitoring can help you detect equipment on it's way to failure.",
        },
      ];
    case model.ASSET_TYPE_RTU:
      return [
        {
          fieldName: 'doPowerMonitoring',
          text: 'Would you like to monitor the power consumption?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Monitoring energy usage gives you detailed usage/cost information, equipment state (on/off) data, and provides additional data points for equipment health monitoring.',
        },
        {
          fieldName: 'doVibrationAndTempMonitoring',
          text: 'Do you want to monitor the health of the compressor and fan motors in this unit?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Monitoring the motors in these units can prevent downtime and decrease the cost of repairs.',
        },
        {
          fieldName: 'doClimateControls',
          text: 'Do you want to remotely control the RTU?',
          default: 'No',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'SimplySnap can remotely and holistically coordinate the control of your HVAC to save money.',
        },
      ];
    case model.ASSET_TYPE_COMPRESSOR:
      return [
        {
          fieldName: 'doPowerMonitoring',
          text: 'Would you like to monitor the power consumption?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Compressor Energy Monitoring is especially valuable since compressors are usually the most inefficient energy consumers in industrial facilities.',
        },
        {
          fieldName: 'numOfHealthEndPoints',
          text: 'How many components of this compressor would you like to monitor for Machine State and Health?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'Compressors sometimes have multiple motors powering things like the compression mechanism, cooling fans, and the like.  Failure of any one of these can cause permanent damage.',
        },
        {
          fieldName: 'doFlowMonitoring',
          text: 'Would you like to monitor the air flow from this compressor individually?',
          default: 'No',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Individual Compressor flow monitoring can give you insights into ROI for upgrades as well as a compressors contribution to a supply trunk.',
        },
        {
          fieldName: 'doPressureMonitoring',
          text: 'Would you like to monitor the air pressure from this compressor individually?',
          default: 'No',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Individual Compressor pressure monitoring can give you insights into the health of your compressed air system.',
        },
        {
          fieldName: 'doTemperatureMonitoring',
          text: 'Would you like to monitor the air temperature from this compressor individually?',
          default: 'No',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Individual Compressor temperature monitoring can warn you of compressor over-heating and other system inefficiencies.',
        },
        {
          fieldName: 'isSchedulePipe',
          text: 'Is the output of this compressor schedule sized pipe?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'The type of pipe the compressor output is connected to can affect the type of sensors used.',
        },
      ];
    case model.ASSET_TYPE_SILO:
      return [
        {
          fieldName: 'doLevelMonitoring',
          text: 'Would you like to monitor and/or alert on the level of the material in this silo?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Level monitoring and alerts can help you avoid running out of material as well as track your usage over time.',
        },
      ];
    case model.ASSET_TYPE_SCHEDULE_WATER_PIPE:
      return [
        {
          fieldName: 'pipeMaterial',
          text: 'What is the material of this pipe?',
          default: 'Steel',
          format: DropDownQuestion,
          options: ['Steel', 'Stainless Steel', 'Aluminium', 'Brass', 'Copper', 'PVC', 'Other'],
          tooltip: 'The material of the pipe will determine the types of sensor that can be used.',
        },
        {
          fieldName: 'pipeSchedule',
          text: 'What is the outside diameter of this pipe?',
          default: 'Schedule 40',
          format: DropDownQuestion,
          options: ['Schedule 20', 'Schedule 40', 'Schedule 80', 'Schedule 120', 'Other'],
          tooltip: 'The schedule of the pipe will determine the types of sensor that can be used.',
        },
        {
          fieldName: 'pipeDiameter',
          text: 'What is the schedule diameter of this pipe?',
          default: '1/2"',
          format: DropDownQuestion,
          options: [
            '1/8"',
            '1/2"',
            '3/4"',
            '1"',
            '1 1/4"',
            '1 1/2"',
            '2"',
            '2 1/2"',
            '3"',
            '4"',
            'Other',
          ],
          tooltip:
            'The schedule diameter of the pipe will determine the types of sensor that can be used.',
        },
      ];
    case model.ASSET_TYPE_NON_SCHEDULE_WATER_PIPE:
      return [
        {
          fieldName: 'pipeMaterial',
          text: 'What is the material of this pipe?',
          default: 'Steel',
          format: DropDownQuestion,
          options: ['Steel', 'Stainless Steel', 'Aluminium', 'Brass', 'Copper', 'PVC', 'Other'],
          tooltip: 'The material of the pipe will determine the types of sensor that can be used.',
        },
        {
          fieldName: 'pipeOuterDiameter',
          text: 'What is the outside diameter (inches) of this pipe?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'The outside diameter of the pipe will determine the types of sensor that can be used.',
        },
        {
          fieldName: 'pipeInnerDiameter',
          text: 'What is the inside diameter (inches) of this pipe?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'The inside diameter of the pipe will determine the types of sensor that can be used.',
        },
      ];
    case model.ASSET_TYPE_SCHEDULE_COMPRESSED_AIR_PIPE:
      return [
        {
          fieldName: 'pipeMaterial',
          text: 'What is the material of this pipe?',
          default: 'Steel',
          format: DropDownQuestion,
          options: ['Steel', 'Stainless Steel', 'Aluminium', 'Brass', 'Copper', 'PVC', 'Other'],
          tooltip: 'The material of the pipe will determine the types of sensor that can be used.',
        },
        {
          fieldName: 'pipeSchedule',
          text: 'What is the outside diameter of this pipe?',
          default: 'Schedule 40',
          format: DropDownQuestion,
          options: ['Schedule 20', 'Schedule 40', 'Schedule 80', 'Schedule 120', 'Other'],
          tooltip: 'The schedule of the pipe will determine the types of sensor that can be used.',
        },
        {
          fieldName: 'pipeDiameter',
          text: 'What is the schedule diameter of this pipe?',
          default: '1/2"',
          format: DropDownQuestion,
          options: [
            '1/8"',
            '1/2"',
            '3/4"',
            '1"',
            '1 1/4"',
            '1 1/2"',
            '2"',
            '2 1/2"',
            '3"',
            '4"',
            'Other',
          ],
          tooltip:
            'The schedule diameter of the pipe will determine the types of sensor that can be used.',
        },
        {
          fieldName: 'pipePressure',
          text: 'What is the estimated pressure (psi) of the air in this pipe?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'The pressure of the air in the pipe will determine the types of sensor that can be used.',
        },
      ];
    case model.ASSET_TYPE_NON_SCHEDULE_COMPRESSED_AIR_PIPE:
      return [
        {
          fieldName: 'pipeMaterial',
          text: 'What is the material of this pipe?',
          default: 'Steel',
          format: DropDownQuestion,
          options: ['Steel', 'Stainless Steel', 'Aluminium', 'Brass', 'Copper', 'PVC', 'Other'],
          tooltip: 'The material of the pipe will determine the types of sensor that can be used.',
        },
        {
          fieldName: 'pipeOuterDiameter',
          text: 'What is the outside diameter (inches) of this pipe?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'The outside diameter of the pipe will determine the types of sensor that can be used.',
        },
        {
          fieldName: 'pipeInnerDiameter',
          text: 'What is the inside diameter (inches) of this pipe?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'The inside diameter of the pipe will determine the types of sensor that can be used.',
        },
        {
          fieldName: 'pipePressure',
          text: 'What is the estimated pressure (psi) of the air in this pipe?',
          default: 0,
          format: NumberQuestion,
          options: [],
          tooltip:
            'The pressure of the air in the pipe will determine the types of sensor that can be used.',
        },
      ];
    case model.ASSET_TYPE_CIRCUIT_PANEL:
    case model.ASSET_TYPE_CONVEYOR:
    case model.ASSET_TYPE_FURNACE:
    case model.ASSET_TYPE_MAU:
    case model.ASSET_TYPE_SINGLE_PHASE_TRANSFORMER:
    case model.ASSET_TYPE_COMMISSIONED:
    case model.ASSET_TYPE_FILTERSENSE_BPAC:
    case model.ASSET_TYPE_CHILLER:
    case model.ASSET_TYPE_CNC:
    case model.ASSET_TYPE_CONDENSER:
    case model.ASSET_TYPE_COOLING_TOWER:
    case model.ASSET_TYPE_CUPOLA:
    case model.ASSET_TYPE_EXHAUST_FAN:
    case model.ASSET_TYPE_EXTRUDER:
    case model.ASSET_TYPE_MAINS:
    case model.ASSET_TYPE_SWITCH_GEAR:
    case model.ASSET_TYPE_MIXER:
    case model.ASSET_TYPE_MULLER:
    case model.ASSET_TYPE_PAINT_BOOTH:
    case model.ASSET_TYPE_PRESS:
    case model.ASSET_TYPE_SCRUBBER:
    case model.ASSET_TYPE_SHOT_BLAST:
    case model.ASSET_TYPE_STAMPING_MACHINE:
    case model.ASSET_TYPE_WELDER:
    case model.ASSET_TYPE_OTHER:
    case model.ASSET_TYPE_NATURAL_GAS_PIPE:
    case model.ASSET_TYPE_TANK:
      return [];
    case model.ENDPOINT_TYPE_MACHINE_HEALTH:
      return [
        {
          fieldName: 'location',
          text: 'Notes; such as a specific location or sub-component.',
          default: 'None',
          format: NotesQuestion,
          options: [],
          tooltip: 'Any information that might be important to installation later.',
        },
      ];
    case model.ENDPOINT_TYPE_SILO_LEVEL:
      return [
        {
          fieldName: 'isInstalledSensor',
          text: 'Is there already an existing 4-20 mA level sensor installed in this silo?',
          default: 'No',
          format: YesNoQuestion,
          options: [],
          tooltip:
            "An existing 4-20mA sensor makes this a snap, even if it's connected to an existing system.",
        },
        {
          fieldName: 'warningThreshold',
          text: 'At what percent remaining would you want someone to be notified about this silo level?',
          default: '25',
          format: PercentQuestion,
          options: [],
          tooltip: 'Typically the volts RMS, line-to-line.',
        },
      ];
    case model.ENDPOINT_TYPE_POWER:
      return [
        {
          fieldName: 'voltage',
          text: 'Voltage (AC)',
          default: '480',
          format: NumberQuestion,
          options: [],
          tooltip: 'Typically the volts RMS, line-to-line.',
        },
        {
          fieldName: 'phases',
          text: 'Phase Configuration',
          default: '3-Phase',
          format: DropDownQuestion,
          options: ['3-Phase', 'Single Phase'],
          tooltip: 'The phase configuration of this circuit.',
        },
        {
          fieldName: 'power',
          text: 'Max Power',
          default: '50',
          format: NumberQuestion,
          options: [],
          tooltip: 'The maximum power this circuit could draw.',
        },
        {
          fieldName: 'unit',
          text: 'Max Power Units',
          default: POWER_UNIT_AMPS,
          format: DropDownQuestion,
          options: [POWER_UNIT_AMPS],
          tooltip: 'The power units describing the maximum power draw.',
        },
        {
          fieldName: 'outdoors',
          text: 'Exposed to Weather',
          default: 'No',
          format: YesNoQuestion,
          options: [],
          tooltip: 'Devices exposed to the heat, cold, rain, etc. need special ratings.',
        },
        {
          fieldName: 'insulated',
          text: 'Cable is Insulated',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip: 'Uninsulated cables require special consideration.',
        },
        {
          fieldName: 'extrapolation',
          text: 'If 3-phase, extrapolate other phases?',
          default: 'Yes',
          format: YesNoQuestion,
          options: [],
          tooltip:
            'Loads that are near balanced can often be extrapolated using a single sensor instead of one on every phase.',
        },
      ];
    case model.ENDPOINT_TYPE_COMMISSIONED:
    case model.ENDPOINT_TYPE_COMPRESSED_AIR_FLOW:
    case model.ENDPOINT_TYPE_COMPRESSED_AIR_PRESSURE:
    case model.ENDPOINT_TYPE_COMPRESSED_AIR_TEMP:
    case model.ENDPOINT_TYPE_DIFFERENTIAL_PRESSURE:
    case model.ENDPOINT_TYPE_PARTICLE_SENSING:
    case model.ENDPOINT_TYPE_CLIMATE_CONTROL:
      return [];
    default: {
      assertNever(type);
      return [];
    }
  }
}
